.container {
    width: 100vw;
    height: 100vh;
    background-image: url(../../../assets/images/validation.png);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 32px 120px;

    .box {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: var(--background1);
        box-shadow: var(--box-shadow4);
        border-radius: var(--border-radius9);
        position: relative;
        .logo {
          position: absolute;
          width: 103px;
          height: 32px;
          top: 24px;
          left: 32px;
          background-size: 100% auto;
          // background-image: url(../../../assets/images/loginLogo.png);
        }
        .content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-top: 80px;

            .title {
                font-size: var(--text-font7);
                color: var(--text-font-color6);
                font-weight: var(--text-font-weight2);
                margin-bottom: 25px;
            }
        }
    }
}