.UserMes {
    display: flex;
    flex-direction: row;
    height: 100%;
    margin-right: 24px;

    .left-aside {
        width: 250px;
        height: 100%;
        box-shadow: var(--box-shadow2);
        align-items: center;
        min-width: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;

        position: relative;

        .left-aside-container {
            overflow: scroll;
            // height: 85vh;
            // height: 96%;
            height: calc(100vh - 56px - 92px);
            padding-bottom: 20px;

            .left_aside_main {
                padding: 0px 16px;
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 24px;
                width: 100%;
            }

            .UserDeatil_idText {
                display: flex;
                margin-top: 16px;
                color: var(--text-font-color18);
                //   word-break: break-word;
            }

            .UserDeatil_idText-oneid {
                display: -webkit-box;
                word-break: break-all;
                text-overflow: ellipsis;
                overflow: hidden;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
            }
        }

        .left-aside-container::-webkit-scrollbar {
            width: 0;
        }

        .left-aside-container::-webkit-scrollbar {
            height: 0;
        }

        .lzHeader_avatar {
            width: 64px;
            height: 64px;
            border-radius: 50%;
        }
    }

    .left-aside :global {
        .ant-tabs-tab {
            width: 105px;
            justify-content: center;
        }
    }

    .content {
        flex: 1;
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: scroll;


        padding: 24px;

        .header {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            justify-content: space-between;

            .header-left {
                display: flex;
                flex-direction: row;
                align-items: center;
            }
        }

        .header-tag {
            display: flex;
            flex-wrap: wrap;

            .header-tag-info {
                width: calc(100% / 3);
                margin-top: 10px;
            }
        }

        .content_tabs {
            margin-top: 20px;
        }
    }

    .UserDeatil_ItemBox {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;
        width: 100%;

        :global {
            .ant-collapse-header {
                padding: 12px 0px;
            }

            .ant-collapse-header .ant-collapse-expand-icon {
                position: absolute;
                right: 0;
            }

            .ant-collapse-content-box {
                padding: 0px;
            }
        }
    }

    .UserDeatil_top-mes {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
    }

    .UserDeatil_Item {
        display: flex;
        width: 100%;
        margin: 6px 0px;
    }

    .UserDeatil_Item:nth-child(1) {
        // margin-top: 0px;
    }

    .UserDeatil_Item-title {
        min-width: 40%;
        color: var(--text-font-color18);
    }

    .UserDeatil_changeMes-icon {
        color: var(--text-font-color18);
    }

    .UserDeatil_changeMes-icon1 {
        margin-right: 8px;
        color: var(--text-font-color18);
    }

    .UserDeatil_Item-text {
        flex: 1;
        word-break: break-word;
    }

    .UserDeatil_Tooltip-box {
        display: flex;
        flex-direction: column;
    }

    .UserDeatil_mesImg {
        width: 15px;
        height: 15px;
        margin-right: 2px;
    }

    .UserDeatil_mes-text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100px;
    }
}

.UserDeatil_Itm-iconCopy {
    margin-left: 10px;

    &:hover {
        color: var(--text-font-color7);
    }
}