.cardItems{
  border-radius: var(--border-radius7);
  margin-bottom: 24px;
  border: var(--border3);
}

.headBox{
  height: 48px;
  line-height: var(--text-font16);
  border-bottom: var(--border3);
  display: flex;
  justify-content: space-between;
}

.baseInfo{
  display: flex;
  .dataName{
    margin-left: 10px;
    font-weight: var(--text-font-weight3);
    font-size: var(--text-font5);
    color: var(--text-font-color9);
  }
}

.bodyBox{
  padding: 20px 0 20px 8px;
}

.progressBox{
  margin-bottom: 20px;
}

.progressBox :global{
  .ant-progress{
    margin-bottom: 0;
  }
}

.timeText{
  padding-left: 4px;
  font-weight: normal;
  font-size: var(--text-font5);
  color: var(--text-font-color4);
  line-height: var(--text-font11);
}

.itemText{
  div{
    font-weight: normal;
    font-size: var(--text-font5);
    color: var(--text-font-color4);
    line-height: var(--text-font11);
    margin-bottom: 8px;
  }
}