.productSales {
    padding: 0 24px;
}

.wrap {
    display: flex;
    gap: 24px;
    width: 100%;
    margin-top: 16px;
}

.container {
    margin: 0 24px;
}

.normalBox {
    .normalHeader {
        line-height: 24px;
        font-size: 16px;
        padding: 28px 0;
        color: rgba(0, 0, 0, 0.88);
        display: flex;
        justify-content: space-between;
        font-weight: bold;
    }
}

.newCustom {
    background: #F0F5FF;
}


.newCustomTitle {
    color: #F0F5FF;
    font-weight: bold;
}

.oldCustom {
    background: #F6FFED;
}

.vipCustom {
    background: #E6FFFB;
}
.salesTable :global {
    .ant-table-wrapper .ant-table-thead>tr>th {
        padding: 8px !important;
    }
    // .ant-table-row>.ant-table-cell-row-hover {
    //     background: red !important;
    // }

/* 在 CSS 文件中 */
// .ant-table-thead > tr > th {
//   background-color: #f0f0f0;
// }

// .ant-table-thead > tr > th:nth-child(2) {
//   background-color: #2be046; /* 设置第二列表头背景色 */
// }
}

.checkboxGroup{
    margin-left: 24px;
}
.drawer :global{
    .ant-checkbox-wrapper{
        margin-bottom: 6px;
    }
} 
