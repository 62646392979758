.MemberCardList-bgd {
  padding: 0 24px 24px;
  min-width: 900px;
  box-sizing: border-box;
  .card-list-bgd {
    // margin-top: 40px;
    .card-bgd {
      border-radius: var(--border-radius4);
      overflow: hidden;
      border: var(--border6);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .card-header {
        height: 56px;
        line-height: var(--text-font13);
        padding: 16px 24px;
        box-sizing: border-box;
        font-weight: var(--text-font-weight2);
        font-size: var(--text-font7);
        color: var(--text-font-color9);
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .card-content {
        height: 204px;
        flex-grow: 1;
        line-height: var(--text-font11);
        padding: 16px 24px;
        box-sizing: border-box;
        font-weight: var(--text-font-weight2);
        font-size: var(--text-font5);
        color: var(--text-font-color9);
        border-top: var(--border6);
        border-bottom: var(--border6);
      }
      .card-footer {
        background-color: var(--background2);
        height: 40px;
        padding: 8px 9px;
        display: flex;
        align-items: center;
        justify-content: space-around;
      }
    }
  }
}

.memberCardList-drawer-chooseItem {
  width: 352px;
  height: 98px;
  border-radius: var(--border-radius7);
  border: var(--border3);
  padding: 16px 24px;
  box-sizing: border-box;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    border: var(--border4);
    box-shadow: var(--box-shadow3);
  }
  img {
    width: 32px;
    height: 32px;
    display: block;
    margin-right: 16px;
  }
  .title {
    font-weight: var(--text-font-weight2);
    font-size: var(--text-font5);
    color: var(--text-font-color7);
    line-height: var(--text-font11);
    margin-bottom: 4px;
  }
  .text {
    font-weight: var(--text-font-weight1);
    font-size: var(--text-font2);
    line-height: var(--text-font10);
    color: var(--text-font-color4);
  }
  .sign {
    @extend .text;
    color: var(--text-font-color7);
  }
}
