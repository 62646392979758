.DatasetManagementList {
  display: flex;
  height: 100%;

  .list {
    width: 250px;
    padding: 24px 16px;
    border-right: var(--border1);

    .treeNodeBox {
      width: 100%;
      .DashOutlinedIcon {
        display: none;
        margin-right: 4px;
      }

      &:hover {
        .DashOutlinedIcon {
          display: flex;
        }
      }
    }
  }

  :global .ant-tree-node-content-wrapper {
    margin-left: -4px;
    display: flex;
  }

  :global .ant-tree-title {
    margin-left: 6px;
  }

  :global .ant-tree-title {
    flex: 1;

    p {
      margin: 0;
    }
  }

  .table {
    flex: 1;

    .titleBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 14px 24px;
      border-bottom: var(--border3);

      .title {
        font-size: 20px;
        font-weight: bold;
      }
    }
  }
}