.Resume{
  width: calc(100% - 20px);
  padding-top: 24px;
  .Resume_left-title{
    position: absolute;
    left: -200px;
    width: 150px;
    color: var(--text-font-color26);
  }
  .Resume_step-text{
    color: var(--text-font-color26);
  }
  :global {
    .ant-steps-item-content{
      overflow: visible !important;
    }
    .ant-steps-item-description{
      margin-left: 25px;
    }
    // .ant-steps-icon-dot{
    //   background: var(--background1) !important;
    //   width: 10px;
    //   height: 10px;
    //   border: var(--border11);
    // }
  }
  .Resume_serch-box{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
  }
  .Resume_progressDot-box{
    width: 10px;
    height: 10px;
    border-radius: 50px;
    position: absolute;
    left: -24px;
    top: 10px;
  }
}
