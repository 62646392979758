.container {
  margin: 24px;

  .main {
    width: 70%;
    margin: 0 auto;
    margin-top: 24px;

    .form {
      margin: 24px 0 8px 0;

      .row {
        display: flex;
        // margin-top: 24px;
      }
    }

    .title1 {
      color: var(--text-font-color9);
      font-size: var(--text-font7);
      line-height: var(--text-font13);
      font-weight: bold;
    }

    .title2 {
      color: var(--text-font-color9);
      font-size: var(--text-font5);
      line-height: var(--text-font15);
      margin-right: 8px;
      width: 84px;
      text-align: right;
    }

    .analysisInfo {
      padding: 16px;
      width: 264px;
      height: 110px;
      background: var(--background1);
      // box-shadow: var(--box-shadow3);
      border-radius: var(--border-radius7);
      border: var(--border4);
      box-shadow: 0px 0px 0px 2px rgba(5,145,255,0.1);
    }
    .analysisType{
      color: var(--text-font-color9);
      font-size: var(--text-font2);
      line-height: var(--text-font10);
      margin: 8px 0;
    }
    .analysisDate{
      color: var(--text-font-color9);
      font-size: var(--text-font2);
      line-height: var(--text-font10);
    }
    .btn{
      margin: 8px 0 0 201px;
    }
    .Required{
      color: var(--required);
      margin-right: 4px;
      line-height: var(--text-font19);
    }
  }
}
.BackList_title{
    width: 68px;
    height: 24px;
    background-color: var(--background6);;
    display: flex;
    border-radius: var(--border-radius5);
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    color: var(--text-font-color12);
    cursor: pointer;
    &:hover {
      background: var(--background11);
      color: var(--text-font-color14);
    }
}
.BackList_text{
    margin-left: 6px;
    line-height: var(--text-font13);
}

.pageItem {
  display: flex;
  align-items: flex-start;
  margin-bottom: 24px;
  .title{
    width: 200px;
    height: 32px;
    line-height: var(--text-font15);
    font-weight: var(--text-font-weight1);
    font-size: var(--text-font5);
    color: var(--text-font-color9);
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
