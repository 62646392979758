.LabelMes{
  width: calc(100% - 20px);
  padding-top: 16px;
}
.LabelMes_peopleListBox{
    display: flex;
    flex-wrap: wrap;
  }
  .LabelMes_Item{
    display: flex;
    width: 126px;
    margin-left: 24px;
    margin-top: 16px;
    height: 32px;
    align-items: center;
    justify-content: center;
    background-color: var(--background4);
    border-radius: var(--border-radius5);
  }
.LabelMes_title{
    font-weight: var(--text-font-weight3);
    font-size: var(--text-font5);
}
.search-list {
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: auto;
  padding: 0px 20px;
  overflow: hidden;
  background-color: var(--background2) ;
  margin-bottom: 16px;
}
.LabelMes_serch-box{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}
.LabelMes {
	:global{
    .ant-tree-list-holder-inner {
		  display: block !important;
	  }
    .ant-tree-treenode{
      padding: 10px 0px !important;
    }
  
    .ant-tree-node-content-wrapper:hover{
      background-color: none !important;
    }
  }

	.deepestNode :global{
    .ant-tree-node-selected{
      background-color: var(--background1) !important;
    }
  }
	.deepestNodeChild :global{
		display: inline-flex;
    margin-left: 25px;
    .ant-tree-indent{
      display: none;
    }
    .ant-tree-switcher{
      display: none;
    }
    .ant-tree-node-content-wrapper{
        display: flex;
        width: 136px;
        height: 32px;
        align-items: center;
        justify-content: center;
        background-color: var(--background4);
        border-radius: var(--border-radius5);
    }
    .ant-tree-title{
      width: 70%;
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      white-space: nowrap;
    }
	}

}
