.UserSerch{
  padding: 0px 24px 0 24px;
  max-height: 100%;
  overflow-y: auto;
}

.headBox{
  line-height: var(--text-font15);
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: var(--z-index6);
  padding: 24px 0 24px;
  background-color: var(--background1);
}

.contentSearch{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
}
.titleBox{
  display: flex;
  align-items: center;
}
.contentSearch_Record{
  width: 30%;
  margin-top: 16px;
}
.contentSearch_listBox{
  display: flex;
  width: 100%;
  overflow: hidden;
  flex-wrap: wrap;
}
.recordListItem{
  margin-right: 12px;
  margin-bottom: 10px;
  background-color: var(--background15);
  border: var(--border2);
  padding: 2px  8px;
  border-radius: var(--border-radius5);
}