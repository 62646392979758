.container {
  padding: 16px 24px 20px;
  height: 100%;
  width: 100%;
  background-color: var(--background25);
  position: absolute;
  top: 0;
  left: 0;
  z-index: var(--z-index7);
  .closeButton {
    margin-top: 40px;
    width: 95%;
    display: flex;
    justify-content: end;
  }
  .loginPopup {
    width: 100vw;
    height: 100vh;
    margin-top: -60px;
    transform: scale(0.8);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .ground-container {
    width: 1435px;
    height: 863px;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    background-position: center;
    margin-top: -60px;
    background-image: url('../../../../../../assets/images/dispositionPage.png');

    .groundLogo {
      position: absolute;
      width: 36px;
      height: 28px;
      top: 20px;
      left: 12px;
      // transform: scale(0.8);
    }
    .groundTitle {
      transform: scale(0.8);
      position: absolute;
      left: 66px;
      top: 62px;
      font-weight: var(--text-font-weight4);
      color: var(--text-font-color6);
      font-size: var(--text-font13);
    }
  }
}
