.goodsDetailTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.marginRight5 {
  margin-right: 5px;
}
.fontSize12 {
  font-size: var(--text-font2);
}
.fontSize14 {
  font-size: var(--text-font5);
}
.uploadPic {
  display: flex;
  align-items: top;
}

.uploadText {
  color: var(--text-font-color4);
  margin-left: 16px;
  line-height: 22px;
  font-weight: 400;
  font-size: var(--text-font5);
}
.topTitle {
  font-size: var(--text-font7);
  color: var(--text-font-color9);
  font-weight: 600;
  line-height: 24px;
}
.tableBox {
  margin-top: 24px;
}
.skuName {
  cursor: pointer;
  -webkit-line-clamp: 2;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}
.skuName:hover {
  color: var(--text-font-color7);
}
.skuPic {
  width: 64px;
  height: 64px;
  text-align: center;
  vertical-align: top;
  background-color: var(--background12);
  border: 1px dashed #d9d9d9;
  border-radius: 8px;
  cursor: pointer;
  transition: border-color 0.3s;
}
.imageScale {
  transform: scale(0.64);
  width: 64px;
  height: 64px;
  position: relative;
  margin-top: -10px;
  margin-left: -10px;
  margin-bottom: 12px;
}
.skuPicText {
  color: var(--text-font-color28);
  font-size: var(--text-font3);
}
.popupText {
  color: var(--text-font-color9);
  line-height: 22px;
  margin-bottom: 8px;
  font-size: var(--text-font5);
}
.popupInput {
  width: 472px;
  margin-bottom: 24px;
}
