.platformBox{
  margin-top: 24px;
}

.platformItem{
  display: inline-block;
  width: 100px;
  height: 80px;
  border-radius: var(--border-radius7);
  margin-bottom: 24px;
  cursor: pointer;
  .image{
    margin-top: 14px;
    height: 24px;
    text-align: center;
    margin-bottom: 8px;
  }
  .name{
    font-weight: var(--text-font-weight1);
    font-size: var(--text-font5);
    color: var(--text-font-color3);
    line-height: var(--text-font11);
    text-align: left;
    font-style: normal;
    text-transform: none;
    text-align: center;
  }
}

.borderStyle1{
  border: var(--border3);
}

.borderStyle2{
  border: var(--border4);
  box-shadow: var(--box-shadow3);
}

.image :global{
  .anticon{
    vertical-align: super;
  }
}