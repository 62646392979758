.commonStyle{
  display: inline-block;
  height: 22px;
  line-height: var(--text-font10);
  padding: 0 8px;
  font-size: var(--text-font2);
  font-weight: normal;
  border-radius: var(--border-radius4);
}

.lock{
  color: var(--label2);
  border: var(--label-border2);
  background-color: var(--label-background-color2);
}

.enable{
  color: var(--label1);
  border: var(--label-border1);
  background-color: var(--label-background-color1);
} 

.addData{
  color: var(--label3);
  border: var(--label-border3);
  background-color: var(--label-background-color3);
}

.completionData{
  color: var(--label4);
  border: var(--label-border4);
  background-color: var(--label-background-color4);
}

.disable{
  color: var(--label5);
  border: var(--label-border5);
  background-color: var(--label-background-color5);
}