.createLabelBox{
  padding: 0 0 50px;
}

.pageTitle{
  padding: 0 24px;
  height: 72px;
  line-height: var(--text-font24);
  font-weight: var(--text-font-weight2);
  font-size: var(--text-font10);
  color: var(--text-font-color6);
  border: var(--border3);
  margin-bottom: 33px;
}

.returnBtn{
  height: 24px;
  line-height: var(--text-font13);
  padding: 0 8px;
  display: inline-block;
  font-weight: var(--text-font-weight1);
  font-size: var(--text-font5);
  color: var(--text-font-color4);
  background-color: var(--background3);
  border-radius: var(--border-radius4);
  margin-right: 12px;
  vertical-align: text-bottom;
  margin-top: 1px;
  cursor: pointer;
}

.stepsBox{
  padding: 0 15%;
  margin-bottom: 24px;
}

.formBox{
  padding: 0 21.5%;
  margin-bottom: 54px;
}

.labelBaseInfo{
  font-size: var(--text-font7);
  font-weight: var(--text-font-weight3);
  border: var(--text-font-color9);
  line-height: var(--text-font13);
  margin-bottom: 24px;
  margin-left: 12%;
}

.labelDescription :global{
  .ant-input-disabled{
    color: var(--text-font-color10);
  }
}

.buttonBox{
  text-align: center;
}

.rulesSettingBox{
  padding: 0 24px 16px;
}