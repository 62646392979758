.groupUserInsight {
  padding: 0 24px 24px;
  box-sizing: border-box;
  .titleAndOperate {
    line-height: var(--text-font15);
    display: flex;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: var(--z-index6);
    padding: 24px 0 24px;
    background-color: var(--background1);
    .buttonBox {
      display: flex;
      flex-direction: row;
      align-items: center;
      div {
        // margin-right: 16px;
      }
      img {
        margin-top: 11px;
        cursor: pointer;
      }
      .line {
        height: 16px;
        margin-top: 14px;
        border-left: var(--border3);
      }
    }
  }

  .oneidCopyOutlined {
    cursor: pointer;
    margin-left: 7px;
    &:hover {
      color: var(--text-font-color7);
    }
  }
  .flexSB {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  .groupBox {
    background-color: var(--background2);
    // height: 70px;
    width: 100%;
    border-radius: var(--border-radius4);
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: var(--text-font5);
    margin-bottom: 24px;
    .flexBox {
      width: 100%;
      display: flex;
      align-items: center;
    }
    .flexBox:nth-child(2) {
      margin-top: 16px;
    }
    .peopleNamebox {
      color: var(--text-font-color3);
      // max-width: 150px;
      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
    }
  }
  .chartBox {
    margin-bottom: 24px;
  }
  .cardTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.drawerStyle :global {
  .ant-drawer-body {
    padding: 24px 22px 24px 24px;
  }
}
.drawTitle :global {
  font-size: var(--text-font7);
  font-weight: bold;
  margin-bottom: 24px;
}
.buttonBox :global {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  div {
    // margin-right: 16px;
  }
  img {
    margin-top: 11px;
    cursor: pointer;
  }
  .line {
    height: 16px;
    margin-top: 14px;
    border-left: var(--border3);
  }
}
