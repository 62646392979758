.container {
  padding: 0px 24px ;
  

  .footer-bgd {
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-top: 30px;
  }
}
.header-bgd {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  // height: 32px;
  line-height: var(--text-font15);
  position: sticky;
  top: 0;
  z-index: var(--z-index6);
  padding: 19.5px 24px;
  background-color: var(--background1);
  border-bottom: var(--border8);
  .header-left {
    display: flex;
    align-items: flex-start;
    .header-icon {
      width: 68px;
      height: 24px;
      background-color: var(--background6);;
      display: flex;
      border-radius: var(--border-radius5);
      align-items: center;
      justify-content: center;
      margin-right: 12px;
      color: var(--text-font-color12);
      cursor: pointer;
      &:hover {
        background: var(--background11);
        color: var(--text-font-color14);
      }
      .header_text {
        margin-left: 6px;
        line-height: var(--text-font13);
      }
    }
    .header-text {
      margin-left: 12px;
      font-weight: var(--text-font-weight2);
      font-size: var(--text-font10);
      color: var(--text-font-color6);
      .header-desc {
        margin-top: 10px;
        font-size: var(--text-font2);
        color: var(--text-font-color32);
      }
    }
  }
  .header-right {
    display: flex;
    div {
      // margin-right: 16px;
    }
    img {
      margin-top: 11px;
      cursor: pointer;
    }
    .line {
      height: 16px;
      margin-top: 14px;
      border-left: var(--border3);
    }
  }
}
.field {
  cursor: pointer;
}
.field:hover {
  color: var(--text-font-color7);
}