.CreateMemberCard-bgd {
  min-width: 900px;
  .header-bgd {
    display: flex;
    align-items: center;
    padding: 24px;
    box-sizing: border-box;
    border-bottom: var(--border3);
    .header-icon {
      width: 68px;
      height: 24px;
      line-height: var(--text-font13);
      background: var(--background2);
      border-radius: var(--border-radius4);
      font-size: var(--text-font5);
      text-align: center;
      color: var(--text-font-color4);
      cursor: pointer;
      &:hover {
        background: var(--background11);
        color: var(--text-font-color14);
      }
    }
    .header-text {
      margin-left: 12px;
      font-weight: var(--text-font-weight2);
      font-size: var(--text-font10);
      color: var(--text-font-color6);
    }
  }
  .footer-bgd {
    width: 825px;
    margin-left: 50%;
    transform: translateX(-50%);
    padding-bottom: 24px;
    display: flex;
    // justify-content: center;
    // gap: 8px;
    margin-top: 30px;
  }
}
