.firstLine {
  width: 100%;
  display: flex;
  padding: 16px 0;
  background: var(--background12);
  border-bottom: var(--border3);
}

.normalItem {
  width: calc(100% / 6);
  padding-left: 16px;
  border-right: var(--border3);
}

.picUrl {
  width: 64px;
  height: 64px;
  border-radius: 6px;
  margin-left: 16px;
  margin-right: 8px;
  img {
    border-radius: 6px;
  }
}

.spuName {
  color: var(--text-font-color7);
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  margin-top: 10px;
  white-space: pre-wrap;
  word-break: break-all;
  span {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }
}

.skuName {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.itemStyle {
  color: var(--text-font-color4);
  width: calc(100% - 60px);
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-break: break-all;
}

.tableBox :global {
  .ant-table-row-level-0 .ant-table-cell {
    padding: 0 !important;
  }
  .ant-table-expanded-row-level-1 {
    // background: var(--background12);
  }
  .ant-table-expanded-row-level-1 .ant-table-cell {
    padding: 12px 0 !important;
    background: unset !important;
    margin-bottom: 24px;
  }
  .ant-table-row-expand-icon-cell {
    display: none;
  }
  .ant-table-row:hover > td {
    background-color: inherit !important; /* 或者你想要的任何颜色 */
  }
  .ant-table-row-level-0 .ant-table-cell {
    border-bottom: unset;
  }
  .ant-dropdown-trigger {
    display: inline;
  }
}

.expandTable {
  padding: 12px 0 12px 50px;
  background: var(--background12);
}

.expandTable :global {
  .ant-table-tbody .ant-table-cell {
    padding: 12px 8px !important;
  }
  .css-dev-only-do-not-override-11xg00t {
    margin-inline: 0 !important;
  }
  .ant-table-thead .ant-table-cell {
    padding-left: 8px !important;
  }
  .ant-table-thead {
    background: #fafafa;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: 400;
  }
  .ant-dropdown-trigger {
    background-color: #fff !important;
  }
  .ant-table-cell {
    border-bottom: 1px solid #f0f0f0 !important;
  }
}

.line-three-point :global {
  .ant-dropdown-open {
    background-color: #fff !important;
  }

  .ant-dropdown-open :hover {
    background-color: #fff !important;
  }
}
