.MemberAssetAnalysis-bgd {
  padding: 24px;
  box-sizing: border-box;
  min-width: 1224px;
  .top-section-bgd {
    display: flex;
    margin-top: 24px;
    gap: 24px;
    .commen-section {
      height: 368px;
      background: var(--background20);
      border-radius: var(--border-radius8);
      box-sizing: border-box;
    }
    .left-section {
      @extend .commen-section;
      flex: 1 0 376px;
      padding: 16px 24px;
      .card {
        width: 100%;
        height: 288px;
        background: var(--background1);
        box-shadow: var(--box-shadow6);
        border-radius: var(--border-radius7);
        margin-top: 16px;
        padding: 12px 16px;
        box-sizing: border-box;
        .progress-text {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-weight: var(--text-font-weight1);
          font-size: var(--text-font5);
          color: var(--text-font-color11);
          line-height: var(--text-font11);
          margin-bottom: 2px;
          .num {
            font-weight: var(--text-font-weight2);
            color: var(--text-font-color6);
          }
        }
        .progress {
          width: 100%;
          height: 8px;
          background: var(--background18);
          margin: 5px 0;
          position: relative;
          .rate {
            height: 8px;
            position: absolute;
            top: 0;
            left: 0;
          }
          .dot-top {
            position: absolute;
            top: -5px;
            width: 2px;
            height: 4px;
            background: var(--background19);
            border-radius: var(--border-radius2);
          }
          .dot-bottom {
            @extend .dot-top;
            top: 9px;
          }
        }
        .view-btn {
          margin-left: 50%;
          transform: translateX(-50%);
        }
      }
    }
    .right-section {
      @extend .commen-section;
      flex: 1 0 776px;
      padding: 20px 24px 16px;
    }
    .title-bgd {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: var(--text-font-weight3);
      font-size: var(--text-font7);
      color: var(--text-font-color9);
      line-height: var(--text-font13);
      .num {
        line-height: var(--text-font15);
        font-size: var(--text-font13);
      }
    }
  }
  .table-row {
    height: 64px;
  }
  .table-column-blue {
    background-color: var(--background13);
  }
  .table-column-green {
    background-color: var(--background14);
  }

  .expanded-row-expended {
    background-color: var(--background15);
    & > td:nth-child(3) {
      background-color: var(--background15);
    }
    // & > td:nth-child(1),
    // td:nth-child(2),
    // td:nth-child(3) {
    //   background-color: var(--background15);
    // }
  }
}

.TableDrawer-top-tips {
  width: 352px;
  height: 60px;
  background: var(--background2);
  border-radius: var(--border-radius4);
  font-weight: var(--text-font-weight2);
  font-size: var(--text-font10);
  color: var(--text-font-color9);
  line-height: var(--text-font18);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  box-sizing: border-box;
  .title {
    font-weight: var(--text-font-weight1);
    font-size: var(--text-font5);
    color: var(--text-font-color4);
    line-height: var(--text-font11);
  }
}

.TableDrawer-center-left {
  display: flex;
  align-items: center;
}
.TableDrawer-center-between {
  @extend .TableDrawer-center-left;
  justify-content: space-between;
}
