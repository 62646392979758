.container {
  margin: 0 24px;
}

.headBox {
  line-height: 32px;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 111;
  padding: 24px 0 0 0;
  background-color: #fff;
}

.searchBox {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.complete {
  width: 64px;
  height: 22px;
  background: #F6FFED;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #B7EB8F;
  text-align: center;
  line-height: 20px;
  color: #52C41A;
  font-size: 12px;
}

.fail {
  width: 64px;
  height: 22px;
  background: #FFF1F0;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #FFA39E;
  text-align: center;
  line-height: 20px;
  color: #F5222D;
  font-size: 12px;
}

.pending {
  width: 64px;
  height: 22px;
  background: #E6F4FF;;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #91CAFF;
  text-align: center;
  line-height: 20px;
  color: #1677FF;
  font-size: 12px;
}

.disabledHref {
  cursor: auto;
  color: var(--text-font-color8) !important;
}

.normalHref {
  color: var(--text-font-color9) !important;
}

.line-three-point {
  width: 32px;
  height: 32px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
}
