.groupUserInsight {
  padding: 0 24px 24px;
  box-sizing: border-box;
  .titleAndOperate {
    line-height: var(--text-font15);
    display: flex;
    position: sticky;
    top: 0;
    z-index: var(--z-index6);
    padding: 24px 0 24px;
    background-color: var(--background1);
  }
  .formbox {
    width: 100%;
    padding-left: 69px;
    .peopleinfo {
      margin-top: 8px;
      display: flex;
      color: var(--text-font-color24);
      .peopleNamebox {
        color: var(--text-font-color3);
        max-width: 240px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .tagStyle {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 112px;
      height: 32px;
      background: var(--background1);
      margin-top: 8px;
      border-style: dashed;
      margin-bottom: 16px;
    }
    .addBtn {
      width: 100%;
      border-style: dashed;
    }
    .baseBox {
      width: 751px;
      margin-top: 8px;
      .baseItem {
        margin-bottom: 24px;
      }
      .baseItem:last-child {
        margin-bottom: 0;
      }
    }
  }
  .chartBox {
    margin: 24px 0;
  }
}

.drawerStyle :global {
  .ant-drawer-body {
    padding: 24px 22px 24px 24px;
  }
}
.buttonBox :global {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  div {
    // margin-right: 16px;
  }
  img {
    margin-top: 11px;
    cursor: pointer;
  }
  .line {
    height: 16px;
    margin-top: 14px;
    border-left: var(--border3);
  }
}
