.container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;

    .left-aside {
        width: 250px;
        height: 100%;
        box-shadow: var(--box-shadow2);
        align-items: center;
        min-width: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;

        position: relative;

        .left-aside-container {
            // overflow: scroll;
            // height: 96%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            // overflow-x: hidden;

            .tree-buttons {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 20px;
            }
        }

        .left-aside-container::-webkit-scrollbar {
            display: none;
        }

        .left-aside-container::-webkit-scrollbar {
            width: 0;
        }

        .left-aside-container::-webkit-scrollbar {
            height: 0;
        }


    }

    .left-aside :global {
        .ant-tabs-tab {
            width: 108px;
            justify-content: center;
        }
    }

    .tag-total {
        position: absolute;
        bottom: 5px;
        right: 5px;

        span {
            color: var(--text-font-color7);
        }
    }

    .content {
        flex: 1;
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: scroll;
        padding: 24px;
        scrollbar-width: none;
        /* firefox隐藏滚动条 */
        -ms-overflow-style: none;

        /* IE 10+ */
        .header {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            justify-content: space-between;

            .header-left {
                display: flex;
                flex-direction: row;
                align-items: center;

                .header-left-button :global {
                    margin-left: 10px;
                    margin-top: 2px;

                    .ant-select-selector {
                        transition: none !important
                    }
                }
            }
        }

        .header-tag {
            display: flex;
            flex-wrap: wrap;

            .header-tag-info {
                width: calc(100% / 3);
                margin-top: 10px;
            }
        }

        .content_tabs {
            margin-top: 20px;
        }
    }

    // .content::-webkit-scrollbar {
    //     display: none; /* Chrome Safari */
    // }



    // .accountStatusType0 :global {
    //     .ant-select-selector {
    //         background: var(--label-background-color5) !important;
    //         border: var(--label-border5) !important;
    //         color: var(--label5);
    //         font-size: var(--text-font2);
    //     }

    //     .ant-select-arrow {
    //         color: var(--label5);
    //         transform: scale(.8);
    //     }
    // }

    // .accountStatusType1 :global {
    //     .ant-select-selector {
    //         background: var(--label-background-color1) !important;
    //         border: var(--border20) !important;
    //         color: var(--text-font-color19) !important;
    //         font-size: var(--text-font2);
    //     }

    //     .ant-select-arrow {
    //         color: var(--text-font-color19);
    //         transform: scale(.8);
    //     }
    // }

    .no-data {
        width: 100%;
        border-top: var(--border25);
        margin-top: 20px;
        padding-top: 10px;
        text-align: center;
    }
}