.CustomerAssetAnalysis-bgd {
  padding: 24px;
  box-sizing: border-box;
  .card-list-section {
    min-width: 1176px;
    min-height: 344px;
    background: var(--background20);
    border-radius: 8px 8px 0 0;
    margin-top: 24px;
    padding: 16px 24px;
    box-sizing: border-box;
    .small-card-bgd {
      width: 100%;
      position: relative;
      .flex-between {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .card-title {
          font-weight: var(--text-font-weight3);
          font-size: var(--text-font7);
          color: var(--text-font-color9);
          line-height: var(--text-font13);
          margin-left: 2px;
        }
        .num {
          font-weight: var(--text-font-weight2);
          font-size: var(--text-font13);
          color: var(--text-font-color9);
          line-height: var(--text-font15);
        }
      }
      .card {
        background: var(--background1);
        box-shadow: var(--box-shadow6);
        border-radius: var(--border-radius7);
        margin-top: 16px;
        padding: 12px 16px;
        .progress-text {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-weight: var(--text-font-weight1);
          font-size: var(--text-font5);
          color: var(--text-font-color11);
          line-height: var(--text-font11);
          margin-bottom: 2px;
          .num {
            font-weight: var(--text-font-weight2);
            color: var(--text-font-color6);
          }
        }
        .progress {
          width: 100%;
          height: 8px;
          background: var(--background18);
          margin: 5px 0;
          position: relative;
          .rate {
            height: 8px;
            position: absolute;
            top: 0;
            left: 0;
          }
          .dot-top {
            position: absolute;
            top: -5px;
            width: 2px;
            height: 4px;
            background: var(--background19);
            border-radius: var(--border-radius2);
          }
          .dot-bottom {
            @extend .dot-top;
            top: 9px;
          }
        }
      }
      .card-icon {
        position: absolute;
        left: calc(100% + 30px);
        top: calc(50% + 24px);
        transform: rotate(90deg);
      }
    }
  }

  .table-row {
    height: 64px;
  }
  .table-column-blue {
    background-color: var(--background13);
  }
  .table-column-green {
    background-color: var(--background14);
  }

  .expanded-row-expended {
    background-color: var(--background15);
    & > td:nth-child(3) {
      background-color: var(--background15);
    }
    // & > td:nth-child(1),
    // td:nth-child(2),
    // td:nth-child(3) {
    //   background-color: var(--background15);
    // }
  }
}
