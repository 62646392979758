.Order {
    width: calc(100% - 20px);
    padding-top: 16px;
}

.Order_title {
    font-weight: var(--text-font-weight3);
    font-size: var(--text-font5);
}

.Order_peopleListBox {
    display: flex;
    flex-wrap: wrap;
}

.Order_Item {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 293px;
    margin-left: 23px;
    margin-top: 16px;
    height: 95px;
    justify-content: center;
    border-radius: var(--border-radius5);
    padding: 16px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    box-shadow: var(--box-shadow5);
    background-image: url(../../../../../../assets/images/orderMes.png);
}

.Order_Item:nth-child(1) {
    margin-left: 0px;
}

.Order_Item-value {
    font-weight: var(--text-font-weight2);
    font-size: var(--text-font8);
}

.search-list-text {
    font-weight: var(--text-font-weight2);
    flex: 1;
    display: -webkit-box;
    word-break: break-all;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.search-list-textShop {
    font-weight: var(--text-font-weight2);
    flex: 2;
    display: flex;
    // word-break: break-all;
    // text-overflow: ellipsis;
    // overflow: hidden;
    // -webkit-box-orient: vertical;
    // -webkit-line-clamp: 2;
}

.search-list--icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.Order-list-description {
    display: flex;
    align-items: center;
    border-bottom: var(--border21);
    padding-bottom: 10px;
}

.Order-list-description:nth-last-child(1) {
    border-bottom: none;
    padding-bottom: 0px;
    padding-top: 10px;
}

.Order-list-description-item {
    width: 25%;
    overflow: auto;
}

.Order_serch-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
}

.Order-list-goods-src {
    width: 50px;
    height: 50px;
}

.Order_outside-label :global {
    .ant-table-row {
        background: var(--background15) !important;
    }

}

.Order_inside-label :global {
    .ant-table-row {
        background: var(--background1) !important;
    }

    :where(.css-dev-only-do-not-override-11xg00t) .ant-table-wrapper .ant-table-tbody>tr>td>.ant-table-wrapper:only-child .ant-table {
        margin-inline: 0px !important;
    }

    :where(.css-dev-only-do-not-override-11xg00t).ant-table-wrapper .ant-table-tbody>tr>td {
        padding: 16px 0px;
    }
}

.Order_buy-info {
    width: 100%;
    display: flex;
    flex-direction: row;
    background: var(--background2);
    border-radius: var(--border-radius4);
    padding: 16px 20px;
    margin-top: 24px;

    .Order_buy-info-item {
        display: flex;
        flex-direction: row;
        flex: 1;

        .Order_buy-info-item-title {
            font-size: var(--text-font5);
            color: var(--text-font-color5);
            line-height: var(--text-font10);
        }

        .Order_buy-info-item-detail {
            display: flex;
            flex-direction: column;
            margin: 0 12px;
            flex: 1;

            .Order_buy-info-item-detail-item {
                display: flex;
                flex-direction: row;
                flex: 1;

                .Order_buy-info-item-detail-title {
                    font-weight: var(--text-font-weight2);
                    font-size: var(--text-font5);
                    color: var(--text-font-color5);
                    line-height: var(--text-font10);
                }
            }


        }
    }
}