.container_title {
  display: flex;
  justify-content: space-between;
}

.container_list {
  margin-top: 20px;
  max-height: 450px;
  overflow-y: auto;

  .blueText {
    color: blue;
  }
  .container_list_item {
    white-space: nowrap;
    .container_list_item_name {
      // min-width: 75px;
      // width: 220px;
      text-align: right;
      font-size: var(--text-font2);
      display: inline-block;
      margin-right: 10px;
    }

    .container_list_item_progress {
      width: 80%;
      margin-bottom: 20px;

      div {
        padding: 0;
      }

      span {
        position: absolute;
        left: 0;
        color: var(--background1);
        top: 30%;
        font-weight: var(--text-font-weight6);
      }
    }

    .container_list_item_error {
      border: var(--border9);
      border-radius: 50%;
      padding: 0 9px;
      text-align: center;
      display: inline-block;
      opacity: 0.45;
      transform: scale(0.5);
    }
  }
}

.container_pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
