.MiniStyle-StickText_icon {
  cursor: pointer;
  margin-left: 7px;
  color: var(--text-font-color20);
  &:hover {
    color: var(--text-font-color7);
  }
}

.MiniStyle-TitleWithExpansion-bgd {
  position: sticky;
  top: 0;
  z-index: var(--z-index3);
  background-color: var(--background1);
  padding: 24px 0 24px;
  .MiniStyle-TitleWithExpansion {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-size: var(--text-font10);
      font-weight: var(--text-font-weight2);
    }
    .icon {
      color: var(--text-font-color4);
      font-size: var(--text-font7);
      margin-left: 16px;
    }
  }
}
.MiniStyle-ChannelTag {
  flex-shrink: 0;
  height: 22px;
  line-height: var(--text-font10);
  border-radius: var(--border-radius4);
  padding: 0 8px;
  font-size: var(--text-font2);
  background: var(--background12);
  border: var(--border6);
  color: var(--text-font-color9);
}

.MiniStyle-ChannelTag-JD {
  flex-shrink: 0;
  height: 22px;
  line-height: var(--text-font10);
  border-radius: var(--border-radius4);
  padding: 0 8px;
  font-size: var(--text-font2);
  background: var(--label-background-color5);
  border: var(--label-border5);
  color: var(--label5);
}

.MiniStyle-ChannelTag-TM {
  @extend .MiniStyle-ChannelTag-JD;
  background: var(--background17);
  border: var(--border17);
  color: var(--label6);
}

.MiniStyle-ChannelTag-DY {
  @extend .MiniStyle-ChannelTag-JD;
  background: var(--label-background-color6);
  border: var(--label-border6);
  color: var(--label6);
}

.MiniStyle-ChannelTag-WX {
  @extend .MiniStyle-ChannelTag-JD;
  background: var(--label-background-color1);
  border: var(--border7);
  color: var(--text-font-color19);
}

.MiniStyle-ChannelTag-YZ {
  @extend .MiniStyle-ChannelTag-JD;
  background: var(--background4);
  border: var(--border18);
  color: var(--text-font-color21);
}

.MiniStyle-pageItem-bgd {
  display: flex;
  align-items: flex-start;
  margin-bottom: 24px;
  .title-bgd {
    width: 200px;
    height: 32px;
    line-height: var(--text-font15);
    font-weight: var(--text-font-weight1);
    font-size: var(--text-font5);
    color: var(--text-font-color9);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .require-icon {
      color: var(--required);
      margin-right: 4px;
    }
    .tip-icon {
      margin: 0 1px 0 5px;
      color: var(--text-font-color18);
      font-size: var(--text-font5);
      display: block;
    }
  }
}

.MiniStyle-pageTitle {
  font-weight: var(--text-font-weight2);
  font-size: var(--text-font7);
  color: var(--text-font-color9);
  line-height: var(--text-font13);
  margin-bottom: 24px;
}

.MiniStyle-input-short {
  width: 88px;
  margin: 0 8px;
}
.MiniStyle-input-long {
  width: 359px;
}

.StickText-text:hover {
  color: var(--text-font-color7);
}

.MiniStyle_linesText {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  position: relative;
  .MiniStyle_linesText-hide {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: var(--z-index1);
  }
}

.MiniStyle-tag-green {
  width: max-content;
  height: 22px;
  padding: 1px 8px;
  box-sizing: border-box;
  background: var(--label-background-color1);
  border-radius: var(--border-radius4);
  border: var(--border7);
  font-size: var(--text-font2);
  color: var(--text-font-color19);
  line-height: var(--text-font10);
}

.MiniStyle-tag-red {
  width: max-content;
  height: 22px;
  padding: 1px 8px;
  box-sizing: border-box;
  background: var(--label-background-color5);
  border-radius: var(--border-radius4);
  border: var(--label-border5);
  font-size: var(--text-font2);
  color: var(--required);
  line-height: var(--text-font10);
}
