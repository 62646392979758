.lz_container {
  width: 100vw;
  height: 100vh;
  overflow-x: auto;
  min-width: 1366px;
  /* overflow-x: scroll; */
}

.line-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.content-box {
  width: 100%;
  overflow-x: scroll;
  height: 100%;
  scrollbar-width: none; /* firefox隐藏滚动条 */
  -ms-overflow-style: none; /* IE 10+ */
}

.content-box::-webkit-scrollbar {
  /* width: 0; */
  display: none;
}

/* .content-box::-webkit-scrollbar {
  height: 0;
} */

.ant-drawer-header {
  padding: 16px !important;
}

.line-text-two {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}