.drawerTip {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
.tipItem {
  // width: 50%;
  white-space: nowrap;
  line-height: var(--text-font13);
  color: var(--text-font-color4);
  font-weight: normal;
  margin-right: 25px;
  // font-size: 13px;
}
.headerIcon {
  // width: 50px;
  font-weight: normal;
  font-size: var(--text-font2);
  text-align: center;
  line-height: var(--text-font13);
  background: hsl(198, 66%, 87%);;
  border-radius: var(--border-radius8);
  margin-left: 20px;
  white-space: no-wrap;
  padding: 0px 10px;
  color: var(--text-font-color17);
}
.drawerTableTitle {
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: var(--text-font9);
  color: var(--text-font-color31);
}
.drawerTableClose :global {
  .ant-drawer-close {
    margin-top: -56px;
  }
}