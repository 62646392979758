.userGroupManagement{
  padding: 0 24px 0px;
  max-height: 100%;
  overflow-y: auto;
}

.headBox{
  line-height: var(--text-font15);
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: var(--z-index6);
  padding: 19.5px 24px;
  background-color: var(--background1);
  border-bottom: var(--border8);
}

.searchBox{
  display: flex;
  flex-direction: row;
  align-items: center;
}


.createRolePromit{
  margin: 0;
  padding: 0;
  height: 150px;
  line-height: var(--text-font25);
  text-align: center;
  span{
    display: inline-block;
    margin-left: 20px;
    cursor: pointer;
    color: var(--text-font-color7);
  }
}
.line-three-point{
  width: 32px;
  height: 32px;
  border-radius: var(--border-radius5);
  display: flex;
  justify-content: center;
}

