.groupUserInsight {
  padding: 0 24px 24px;
  box-sizing: border-box;
  .titleAndOperate {
    line-height: var(--text-font15);
    display: flex;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: var(--z-index6);
    padding: 24px 0 24px;
    background-color: var(--background1);
    .buttonBox {
      display: flex;
      flex-direction: row;
      align-items: center;
      div {
        // margin-right: 16px;
      }
      img {
        margin-top: 11px;
        cursor: pointer;
      }
      .line {
        height: 16px;
        margin-top: 14px;
        border-left: var(--border3);
      }
    }
  }

  .oneidCopyOutlined {
    cursor: pointer;
    margin-left: 7px;
    &:hover {
      color: var(--text-font-color7);
    }
  }
}
