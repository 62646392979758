.container {
    margin: 0 24px;
}

.normalBox {
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.08);

    .normalHeader {
        line-height: 24px;
        padding: 12px;
        color: rgba(0, 0, 0, 0.88);
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
        font-weight: bold;
    }

    .normalContent {
        padding: 24px 12px;
        display: flex;
        gap: 24px;
    }
}

.groupBox {
    flex: 1;
    height: 258px;
    display: flex;
    gap: 8px;

    .itemTotal {
        flex: 1;
        border-radius: 8px;
        padding: 42px 12px;

        .title {
            font-size: 14px;
            font-weight: bold;
            color: rgba(0, 0, 0, 0.85);
            line-height: 22px;
        }
    }

    .itemChildWrap {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 8px;

    }

    .retention {
        background: #BAE0FF;
    }

    .winback {
        background: #D6E4FF;
    }

    .sleeping {
        background: #F0F5FF;
    }
}


.icon {
    width: 24px;
    height: 24px;
    ;
}

.grayTitle {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45);
    line-height: 22px;
}

.bigValue {
    font-size: 24px;
    font-weight: bold;
    line-height: 32px;
    color: rgba(0, 0, 0, 0.85);
    margin-top: 4px;
}

/* 向下的三角箭头 */
.arrowDown {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid #52C41A;
}

/* 向上的三角箭头 */
.arrowUp {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 6px solid #F5222D;
}
        .itemChild {
            border-radius: 8px;
            padding: 0 12px;
        }
