.cardTitle {
  height: 69px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .titleBox {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
