.textStyle{
  line-height: var(--text-font11);
  font-weight: normal;
  font-size: var(--text-font5);
  color: var(--text-font-color9);
  margin-bottom: 32px;
  span{
    display: inline-block;
    margin-right: 4px;
    color: var(--required);
  }
}