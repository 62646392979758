.lz_sub-menu-container {
    margin-top: 14px;
}

.lz_sub-menu-title {
    font-size: var(--text-font10);
    color: var(--text-font-color3);
    font-weight: var(--text-font-weight2);
    margin-top: 20px;
    margin-left: 8px;
}

.lz_sub-menu :global {
    width: 152px;
    background: var(--background1);
    border-right: var(--border1);
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: relative;
    // .ant-menu-submenu-selected {
    //     .anticon {
    //         // color: var(--text-font-color13); /* 设置你想要的未选中颜色 */
    //         color: var(--text-font-color7)
    //     }
    // }
    // .anticon {
    //     // color: var(--text-font-color13); /* 设置你想要的未选中颜色 */
    //     color: var(--text-font-color13);
    // }
    .ant-menu-submenu-selected {
        .ant-menu-submenu-title .anticon svg {
            color: var(--text-font-color7) !important
        }
        .ant-menu-submenu-arrow {
            color: var(--text-font-color7) !important
        }
    }

    .ant-menu-inline {
        border-inline-end: none !important;
    }


    .ant-menu-submenu-title {
        font-weight: var(--text-font-weight2);
        font-size: var(--text-font5);
        color: var(--text-font-color9);
        padding-inline-end: 0;
    }

    .ant-menu-item-selected {
        .ant-menu-title-content {
            color: var(--text-font-color7) !important;
        } 
        
    }

    .ant-menu-submenu-title {
        padding: 8px 0 8px 20px !important;
        // height: 36px !important;
        // line-height: var(--text-font17) !important;
    }

    .ant-menu-sub.ant-menu-inline {

        background-color: var(--background1) !important;
    }

    .ant-menu-sub {


        .ant-menu-title-content {
            font-size: var(--text-font2);
            font-weight: var(--text-font-weight2);
            // color: var(--text-font-color11);
            color: var(--text-font-color13);;
        }
    }


    .ant-menu-submenu-title {
        // padding-left: 8px !important;
        padding-left: 0px !important;
        margin-block: 0;

        .anticon+span {
            margin-inline-start: 3px;
        }
        .anticon svg {
            font-size: var(--text-font7);
            font-weight: var(--text-font-weight1);
            color: var(--text-font-color13);;
        }
        
    }

    .ant-menu-submenu-arrow {
        inset-inline-end: 0;
        color: var(--text-font-color13);;
        font-weight: var(--text-font-weight1);
    }

    .ant-menu-item {
        // padding-left: 25px !important;
        padding: 8px 0 8px 20px !important;
        // width: 136px;
        // height: 36px;
        margin-block: 0;
        width: calc(100% - 8px);

    }

    .ant-menu-submenu-title {
        // height: 36px;
        // line-height: var(--text-font17);
        // margin-bottom: 8px !important;
    }

    .ant-menu-inline {
        background: var(--background1);
    }

    .lz_sub-menu-arrow {
        position: absolute;
        width: 24px;
        height: 24px;
        background: var(--background1);
        opacity: 1;
        border: var(--border1);
        border-radius: 50%;
        right: -12px;
        top: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}