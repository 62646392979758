.menu-item {
  width: 48px;
  height: 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  border-radius: var(--border-radius7);
  margin-bottom: 16px;
}

.menu-icon {
  font-weight: var(--text-font-weight1);
  color: var(--text-font-color1);
  font-size: var(--text-font1);
  margin-top: 8px;
  line-height: var(--text-font1);
}

.memu-text {
  font-size: var(--text-font2);
  color: var(--text-font-color2);
  font-weight: var(--text-font-weight2);
  margin-top: 4px;
  line-height: var(--text-font10);
  font-weight: var(--text-font-weight1);
}

.lz_main-menu-selected {
  background: var(--background8);


  .menu-icon {
      font-weight: var(--text-font-weight2);
      color: var(--text-font-color2);
  }

  .menu-text {
      color: var(--text-font-color2);
      font-weight: var(--text-font-weight2);
  }
}

.lz_main-menu_setting {
  position: absolute;
  bottom: 20px;
}