.lz_login-bind-logo {
    width: 68px;
    height: 24px;
}

.lz_login-bind-container {
    /* width: 100vw; */
    /* display: flex;
    flex-direction: column; */
    background-color: var(--background1);
    /* align-items: center; */
}

.lz_login-bind-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 32px;
}

.lz_login-bind-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.lz_login-bind-title {
    font-size: var(--text-font7);
    color: var(--text-font-color3);
    font-weight: var(--text-font-weight2);
}

.lz_login-bind-text {
    font-size: var(--text-font5);
    color: var(--text-font-color6);
    font-weight: var(--text-font-weight2);
}

.lz_login-bind-container .ant-steps-item-icon {
    width: 22px;
    height: 22px;
    line-height: var(--text-font11);
    font-size: var(--text-font5);
}

.lz_login-bind-container .ant-steps-item-title {
    font-size: var(--text-font5);
}

.lz_login-bind-container .ant-steps-item-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 33px;
}

.lz_loginbind-operation {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.lz_login-bind-box .ant-input-prefix {
    color: var(--text-font-color4);
}

.lz_login-bind-forget {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 31px;
}

.lz_login-bind-forget-validation {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.lz_login-forget-validation-way {
    width: 282px;
    height: 82px;
    border-radius: var(--border-radius7);
    opacity: 1;
    border: var(--border3);
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.lz_login-forget-validation-way-top {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.lz_login-forget-validation-way-selected {
    box-shadow: var(--box-shadow3);
    border: var(--border4);
    color: var(--text-font-color7)
}

.lz_login-bind-way-title {
    font-size: var(--text-font7);
    font-weight: var(--text-font-weight2);
}

.lz_login-forget-validation-way-disable {
    width: 282px;
    height: 82px;
    background: var(--background2);
    border-radius: var(--border-radius7);
    opacity: 1;
    border: var(--border3);
}

.lz_login-forget-prompt-disable {
    font-size: var(--text-font2);
    font-weight: normal;
    color: var(--text-font-color4);
    margin-top: 4px;
}