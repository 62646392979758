.container :global {
    width: 100%;
    background-color: var(--background2);
    border-radius: var(--border-radius4);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    overflow: visible;
    // margin: 24px 0;
    margin-top: 24px;

    .search-list {
        width: 100%;
        display: flex;
        flex-direction: row;
        overflow: hidden;

        .search-item {
            width: calc(100% / 3);
            display: flex;
            flex-direction: row;
            align-items: center;

        }
    }
    .ant-input-suffix{
        margin-inline-end: 1px;
    }
    // .ant-form-item {
    //     margin-bottom: 16px;
    // }
}

.inputDouble-text{
    margin-top: 3px;
}
// .search-checkBox :global{
//     .ant-checkbox .ant-checkbox-inner {
//         border-radius: var(--border-radius10) !important;
//     }
// }
.inputDouble-selectIcon{
    margin-right: 3px;
}
.specailInput :global{
    .ant-form-item-control-input {
        text-align: right;
    }
    .ant-checkbox+span {
        padding-inline-end: 0px;
    }
}