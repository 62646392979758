.UserInsightUserSerch{
  padding: 0px 24px 0 24px;
  max-height: 100%;
  overflow-y: auto;
}

.headBox{
  line-height: var(--text-font15);
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: var(--z-index6);
  padding: 19.5px 24px;
  background-color: var(--background1);
  border-bottom: var(--border8);
}

.searchBox{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.titleBox{
  display: flex;
  align-items: center;
}
.peopleListBox{
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  margin-left: 80px;
}
.peopleListItem{
  display: flex;
  flex: 1 1 33.33%;
  margin-top: 10px;
  .peopleListItem_title{
    min-width: 65px;
  }
}
.tableBox{
  // margin-top: 24px;
}

.accountStatusType0 :global{
  .ant-select-selector {
    background: var(--background4) !important;
    border: var(--border19) !important;
    color: var(--text-font-color7);
}

  .ant-select-arrow {
      color: var(--text-font-color7);
  }
}
.userlist_operate{
  display: flex;
  justify-content: space-between;
}
.UserCopyOutlined {
  cursor: pointer;
  margin-left: 7px;
  color: var(--text-font-color20);
  &:hover {
    color: var(--text-font-color7);
  }
}
.oneidCopyOutlined {
  cursor: pointer;
  margin-left: 7px;
  &:hover {
    color: var(--text-font-color7);
  }
}
.list_channelType{
  width: 20px;
  height: 20px;
  margin: 0 5px;
  cursor: pointer;
}
.UserDeatil_mesImg{
  width: 15px;
  height: 15px;
  margin-right: 2px;
}