.relationshipDivider{
  position: relative;
}

.line{
  width: 0;
  height: 100%;
}

.text{
  width: 28px;
  height: 28px;
  line-height: var(--text-font18);
  text-align: center;
  background-color: var(--background1);
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  left: -14px;
  top: calc(50% - 14px);
  border: var(--border3);
}
