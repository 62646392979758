.buttonBox {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  div {
    margin-right: 16px;
  }
  img {
    margin-top: 11px;
    cursor: pointer;
  }
  .line {
    height: 16px;
    margin-top: 14px;
    border-left: var(--border3);
  }
}
