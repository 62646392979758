.CustomerTransactionAnalysis-bgd {
  padding: 24px;
  box-sizing: border-box;
  .table-row {
    height: 64px;
  }
  .table-column-blue {
    background-color: var(--background13);
  }
  .table-column-green {
    background-color: var(--background14);
  }
  .expanded-row-bgd {
    background-color: var(--background15);
  }
  .expanded-row-expended {
    background-color: var(--background15);
    & > td:nth-child(3) {
      background-color: var(--background15);
    }
  }
}
