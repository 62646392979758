.GroupUserInsight{
    padding: 0 24px 0px;
    max-height: 100%;
    overflow-y: auto;
  }
  
  .headBox{
    line-height: var(--text-font15);
    display: flex;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: var(--z-index6);
    padding: 19.5px 24px;
    background-color: var(--background1);
    border-bottom: var(--border8);
  }
  
  .searchBox{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  