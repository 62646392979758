.DatasetView {
  .titleBox {
    padding: 14px 24px;
    border-bottom: var(--border3);
    border-top: var(--border1);
    .title {
      font-size: 20px;
      font-weight: bold;
    }
    .filterBox {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 auto;
    }
    .filter {
      width: 100%;
    }
  }
  .tableBox {
    padding: 24px;
  }
}