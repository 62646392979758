.titleAndOperate{
  line-height: var(--text-font15);
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: var(--z-index6);
  padding: 20px 24px;
  background-color: var(--background1);
  border-bottom: var(--border8);
  .buttonBox{
    display: flex;
    flex-direction: row;
    align-items: center;
    div{
      // margin-right: 16px;
    }
    img{
      margin-top: 11px;
      cursor: pointer;
    }
    .line{
      height: 16px;
      margin-top: 14px;
      border-left: var(--border3);
    }
  }
}
.dataAccess{
  padding: 0 24px 0px 24px;
  box-sizing: border-box;
  
}
 
.drawerStyle :global{
  .ant-drawer-body{
    padding: 24px 22px 24px 24px;
  }
}

.stepsStyle :global{
  .ant-steps-item-title{
    font-size: var(--text-font5);
  }

  .ant-steps-item-content{
    width: 130px !important;
  }

  .ant-steps{
    margin-left: -15px;
  }
}

.modalBox{
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  div{
    display: inline-block;
    p{
      text-align: center;
      margin: 0;
      padding: 0;
      font-size: var(--text-font7);
    }
  }
}

