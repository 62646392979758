.PointsChangeRecord-bgd {
  padding: 0 24px 24px;
  box-sizing: border-box;
  .details-bgd {
    display: flex;
    align-items: center;
    margin: 24px 0;
    gap: 24px;
    .detail-card-1 {
      min-width: 276px;
      height: 95px;
      border-radius: var(--border-radius4);
      padding: 16px;
      box-sizing: border-box;
      background-color: var(--background2);
      background-image: url("../../../../assets/images/pointSendNum.png");
      background-repeat: no-repeat;
      background-size: 50px 50px;
      background-position: 220px 40px;
      .title {
        font-size: var(--text-font5);
        color: var(--text-font-color4);
        font-weight: var(--text-font-weight1);
        line-height: var(--text-font11);
      }
      .number {
        font-weight: var(--text-font-weight2);
        font-size: var(--text-font13);
        color: var(--text-font-color6);
        line-height: var(--text-font15);
        margin-top: 7px;
      }
    }
    .detail-card-2 {
      @extend .detail-card-1;
      background-image: url("../../../../assets/images/usePoint.png");
    }
  }
  .table-row {
    height: 64px;
  }
}
