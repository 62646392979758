.container{
    margin: 0 24px;
}

.header{
    margin-top: 18px;
    display: flex;
    justify-content: space-between;
}

.items{
    margin: 20px 0;
}
