.creadRewardEvents-inputBox {
  width: 328px;

  display: flex;
  justify-content: space-between;
  .creadRewardEvents-inputTwo {
    display: flex;
    width: 160px;
    flex-direction: column;
    justify-content: space-between;
    .creadRewardEvents-input {
      width: 160px;
      padding: 0 0 0 11px;
    }
    .creadRewardEvents-div {
      text-align: center;
      // background-color: var(--background12);
      border-left: var(--border6);
      color: var(--text-font-color9);
      padding: 4px 0 4px 11px;
    }
  }
}
.creadRewardEvents-triggerBox {
  height: 100%;
  border-left: var(--border3);
  display: flex;
  align-items: center;
  .creadRewardEvents-triggerItem {
    margin-right: 20px;
    margin-left: -13px;
    background-color: var(--background4);
    padding: 2px 6px;
    border-radius: var(--border-radius4);
  }
}
