.header-title-box {
  font-size: var(--text-font10);
  font-weight: var(--text-font-weight2);
  color: var(--text-font-color6);
  // padding-left: 16px;
  .header-title {
    margin-bottom: 10px;
  }
  .header-desc {
    font-size: var(--text-font2);
    font-weight: var(--text-font-weight2);
    color: var(--text-font-color29);
    line-height: var(--text-font20);
    display: flex;
    flex-wrap: wrap;
    span {
      margin-right: 20px;
    }
  }
}
.center-title {
  font-size: var(--text-font8);
  font-weight: var(--text-font-weight2);
  color: var(--text-font-color30);
  // padding-left: 16px;
}
.center-bgd {
  display: flex;
  justify-content: space-between;
  font-size: var(--text-font8);
  font-weight: var(--text-font-weight2);
  color: var(--text-font-color30);
  .center-right {
    display: flex;
    div {
      margin-right: 16px;
    }
    img {
      margin-top: 11px;
      cursor: pointer;
    }
    .line {
      height: 16px;
      margin-top: 14px;
      border-left: var(--border3);
    }
  }
}

.drawerTip {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
.tipItem {
  // width: 50%;
  white-space: nowrap;
  line-height: var(--text-font21);
  color: var(--text-font-color4);
  font-weight: normal;
  margin-right: 25px;
  font-size: var(--text-font6);
}
.headerIcon {
  width: 50px;
  font-weight: normal;
  font-size: var(--text-font2);
  text-align: center;
  line-height: var(--text-font13);
  background: hsl(198, 66%, 87%);
  border-radius: var(--border-radius8);
  margin-left: 20px;
}
.drawerTableTitle {
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: var(--text-font9);
  color: var(--text-font-color31);
}
.drawerTableClose :global {
  .ant-drawer-close {
    margin-top: -68px;
  }
}
