.container {
  padding: 16px 24px 20px;
  .header-bgd {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header-left {
      display: flex;
      align-items: center;
      .header-icon {
        width: 68px;
        height: 24px;
        background-color: var(--background6);;
        display: flex;
        border-radius: var(--border-radius5);
        align-items: center;
        justify-content: center;
        margin-right: 12px;
        color: var(--text-font-color12);
        cursor: pointer;
        &:hover {
          background: var(--background11);
          color: var(--text-font-color14);
        }
        .header_text {
          margin-left: 6px;
          line-height: var(--text-font13);
        }
      }
      .header-text {
        font-weight: var(--text-font-weight2);
        font-size: var(--text-font10);
        color: var(--text-font-color6);
      }
    }
    .header-right {
      display: flex;
      font-size: var(--text-font5);
    }
  }

  .footer-bgd {
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-top: 30px;
  }
}
.field {
  cursor: pointer;
}
.field:hover {
  color: var(--text-font-color7);
}

.tabs {
  margin-top: 10px;
}

.pre_merger {
  margin-top: 24px;
  .card-item-bgd {
    flex: 1 0 376px;
    height: 661px;
    background: var(--background20);
    border-radius: 16px 16px 0 0;
    padding: 25px 24px;
    box-sizing: border-box;
    .card-item-title {
      display: flex;
      align-items: center;
      // justify-content: space-between;
      font-weight: var(--text-font-weight2);
      font-size: var(--text-font13);
      color: var(--text-font-color9);
      line-height: var(--text-font15);
      .icon {
        width: 22px;
        height: 22px;
        border-radius: var(--border-radius4);
        background-color: var(--background16);
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .title {
        font-weight: var(--text-font-weight2);
        font-size: var(--text-font7);
        color: var(--text-font-color9);
        line-height: var(--text-font13);
        margin-left: 6px;
      }
      img {
        margin-right: 4px;
        transform: translateY(4px);
      }
    }
    .small-card {
      margin-top: 16px;
      background-color: var(--background1);
    }
    .tipItem {
      display: flex;
      white-space: wrap;
      line-height: var(--text-font21);
      font-weight: normal;
      margin-right: 25px;
      font-size: var(--text-font5);
      min-width: 432px;
      color: var(--text-font-color6);
      .tipValue{
        color: var(--text-font-color11);
        width: 400px;
        font-size: var(--text-font5);
        word-break: break-all;
      }
    }
  }
}
