.UserChart {
  padding: 24px 24px 0 24px;
  max-height: 100%;
  overflow-y: auto;
}

.headBox {
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: var(--z-index3);
  background-color: var(--background1);
  padding: 21.5px 24px;
  border-bottom: var(--border8);
}

.titleBox {
  display: flex;
  align-items: center;
  color: var(--text-font-color9);
  font-weight: var(--text-font-weight2);
}

.chartTitle {
  font-weight: var(--text-font-weight2);
  font-size: var(--text-font7);
  color: var(--text-font-color9);
  line-height: var(--text-font13);
}

.channalName {
  font-weight: var(--text-font-weight2);
  font-size: var(--text-font8);
  color: var(--text-font-color9);
  line-height: var(--text-font13);
  margin-right: 10px;
}

.borderStyle {
  background: var(--background1);
  box-shadow: var(--box-shadow6);
  border-radius: var(--border-radius7);
  padding: 16px;
  margin: 24px 0 10px;
  // min-width: 350px;
  .name {
    font-size: var(--text-font10);
  }
  .sum {
    font-size: var(--text-font13);
    font-weight: var(--text-font-weight2);
    line-height: var(--text-font15);
  }
}

.rowChartList {
  display: flex;
  // min-height: 390px;
  flex-direction: row;
  justify-content: space-between;
}

.card {
  // background: var(--background1);
  // box-shadow: var(--box-shadow6);
  // border-radius: var(--border-radius7);
  margin-top: 24px;
  // padding: 12px 16px;
  .progress-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: var(--text-font-weight1);
    font-size: var(--text-font5);
    color: var(--text-font-color11);
    line-height: var(--text-font11);
    margin-bottom: 2px;
    .num {
      font-weight: var(--text-font-weight2);
      color: var(--text-font-color6);
    }
  }
  .progress {
    width: 100%;
    height: 8px;
    background: var(--background18);
    margin: 5px 0;
    position: relative;
    .rate {
      height: 8px;
      position: absolute;
      top: 0;
      left: 0;
    }
    .dot-top {
      position: absolute;
      top: -5px;
      width: 2px;
      height: 4px;
      background: var(--background19);
      border-radius: var(--border-radius2);
    }
    .dot-bottom {
      @extend .dot-top;
      top: 9px;
    }
  }
}
.checkboxBgc {
  background-color: var(--background2);
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  padding: 0 27px;
  border-radius: var(--border-radius4);
  margin-bottom: 24px;
}

.card-item-bgd {
  flex: 1 0 376px;
  height: auto;
  background: var(--background20);
  border-radius: 16px 16px 0 0;
  padding: 25px 24px;
  box-sizing: border-box;
}
.meanwhileText {
  font-size: var(--text-font5);
  line-height: var(--text-font11);
  color: var(--text-font-color4);
}
