.boxItem{
    display: flex;
    margin-bottom: 16px;
    img{
        width: 19px;
        height: 19px;
        margin-right: 4px;
    }
    span{
        font-size: 18px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.88);
    }
}