.dateNav{
  display: flex;
  border-radius: var(--border-radius4);
  padding: 5px 12px;
  cursor: pointer;
}

.item{
  width: 80px;
  margin-right: 8px;
  font-weight: var(--text-font-weight2);
  font-size: var(--text-font5);
  line-height: var(--text-font11);
  color: var(--text-font-color9);
}

.timeItem{
  display: inline-block;
  background: var(--background1);
  border-radius: var(--border-radius4);
  width: 80px;
  height: 24px;
  line-height: var(--text-font13);
  font-weight: var(--text-font-weight1);
  font-size: var(--text-font5);
  color: var(--text-font-color9);
  text-align: center;
  border: var(--border6);
  margin-bottom: 15px;
  cursor: pointer;
}