.tag-list-bgd {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  margin: 9px 0;
  img {
    display: block;
    margin-right: 8px;
  }
  .tag-item {
    width: 100%;
    flex: 1;
    display: flex;
    flex-wrap: nowrap;
    gap: 8px;
    overflow: hidden;
    position: relative;
  }
}
