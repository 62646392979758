.memberCard-create-pageOne {
  width: 825px;
  margin: 0 auto;
  .input-long {
    width: 359px;
  }
  .tips {
    max-width: 500px;
    font-weight: var(--text-font-weight1);
    font-size: var(--text-font2);
    color: var(--text-font-color4);
    line-height: var(--text-font11);
  }
  .tips-blue {
    @extend .tips;
    font-weight: var(--text-font-weight3);
    color: var(--text-font-color7);
  }
  .tag-bgd {
    width: 524px;
    margin: 12px 0 0;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    span {
      display: block;
      margin-inline-end: 0;
    }
  }
}
