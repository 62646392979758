.container{
    margin: 0 24px;
}
.normalBox{
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.08);
    .normalHeader{
        line-height: 24px;
        padding: 12px;
        color: rgba(0, 0, 0, 0.88);
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
        font-weight: bold;
    }
}