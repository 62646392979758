.RM {
  p {
    margin: 0;
  }
  .btn-box {
    text-align: right;
    margin-top: 8px;
    margin-bottom: 24px;
  }
}
.RM :global{
  .ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:last-child{
      border-bottom: var(--border8);
  }
}