.searchBox {
  margin-bottom: 24px;
}
.cardTitle {
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
}
.cardBody {
  color: var(--text-font-color4);
  div {
    margin-top: 5px;
  }
}
.paginationSimple {
  margin-top: 10px;
}
.paginationSimple :global {
  .ant-pagination-total-text {
    vertical-align: top;
  }
  }

  .noData{
    width: 100%;
    text-align: center;
    height: 300px;
    line-height: var(--text-font26);
    color: var(--text-font-color4);
  }
  .btnText {
    font-weight: var(--text-font-weight3);
    font-size: var(--text-font5);
    color: var(--text-font-color9);
    width: 230px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0px;
    text-align: left;
  }
  .line {
    width: 100%;
    border-top: 1px solid #E4E8EE ;
    margin-bottom: 16px;
  }
  :where(.css-dev-only-do-not-override-kk0267).ant-popover .ant-popover-content {
    width: 346px;
  }
  // .cardPopover :global {
  //   .css-var-rqs.ant-popover
  //   {
  //     --ant-popover-title-min-width: 277px;
  //   }
  // }
  