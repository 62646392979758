.lz_login-container {
    width: 100vw;
    height: 100vh;
    // background-image: url(../../../assets/images/loginBackground.png);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-position: center;
    min-height: 800px;
    overflow-y: scroll;
    .lz_login-wel {
        width: 700px;
        height: 200px;
        position: absolute;
        left: 120px;
        top: 119px;
        // background-image: url(../../../assets/images/loginWel.png);
        background-size: 100% auto;
        background-repeat: no-repeat;
    }
    
    .lz_login-card {
        position: absolute;
        right: 120px;
        background-color: var(--background7);
        border-radius: var(--border-radius9);
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 400px;
        // padding-bottom: 36px;
        .lz_login-logo {
            width: 155px;
            height: 48px;
            margin-top: 40px;
            background-size: 100% auto;
            // background-image: url(../../../assets/images/loginLogo.png);
        }
        .lz_login-title{
            font-size: var(--text-font10);
            color: var(--text-font-color6);
            width: 336px;
            text-align: left;
            align-items: flex-start;
            font-weight: bolder;
            margin-top: 32px;
        }
        .lz_login-code-image {
            width: 72px;
            height: 30px;
        }
        .lz_login-get-code-text{
            font-size: var(--text-font7);
            font-weight: var(--text-font-weight1);
            padding-left: 0;
            padding-right: 0;
        }
        .lz_login-remember-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .lz_login-remmeber-check-box {
                display: flex;
                flex-direction: row;
                align-items: center;
            }
        }
        .lz_login-button {
            height: 60px;
            font-size: var(--text-font8);
            font-weight: var(--text-font-weight3);
            border-radius: var(--border-radius11);
        }
        .lz_login-divider {
            width: 336px;
            font-size: var(--text-font2) !important;
            color: var(--text-font-color4) !important;
            min-width: 336px;
        }
        
        .lz_login-other-way {
            display: flex;
            flex-direction: row;
            align-items: center;
            .lz_login-way-icon {
                width: 32px;
                height: 32px;
                margin: 0 8px 0;
            }
        }
            .ant-form .ant-form-item {
                margin-bottom: 32px;
            }
        
    }
    .lz_login-provider-divider {
        position: absolute;
        bottom: 31px;
        width: 390px;
        min-width: 390px;
        left: calc(50% - 178px);
        color: var(--text-font-color1);
        // .ant-divider-inner-text {
        //     display: flex!important;
        // }
        .lz_login-contact-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: 10px;
            font-size: var(--text-font5);
        }
    }
    .lz_login-industrial {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: absolute;
        bottom: 12px;
        margin: 0 auto;
        left: 50%;
        transform: translateX(-50%);
        font-size: var(--text-font2);
        color: var(--text-font-color1);
        .lz_login-industrial-img {
            width: 12px;
            height: 12px;
            margin-right: 4px;
        }
    }
}
// .lz_login-card :global {
//     .ant-form .ant-form-item {
//         margin-bottom: 32px;
//     }
// }

.lz_login-forget-button {
    /* float: right; */
}