.Details {
  .header {
    display: flex;
    flex-direction: column;
    border-bottom: var(--border2);

    .titleRow {
      padding: 20px;
      display: flex;
      align-items: center;
      .BackList_title {
        width: 68px;
        height: 24px;
        background-color: var(--background6);
        display: flex;
        border-radius: var(--border-radius5);
        align-items: center;
        justify-content: center;
        margin-right: 12px;
        color: var(--text-font-color12);
        cursor: pointer;
  
        &:hover {
          background: var(--background11);
          color: var(--text-font-color14);
        }
      }
  
      .BackList_text {
        margin-left: 6px;
        line-height: var(--text-font13);
      }
    }
    .reportInfo {
      flex: 1;
      padding: 0 20px 20px 100px;
      display: flex;
      .col {
        margin-bottom: 8px;
        font-size: 14px;
        display: flex;
        span {
          &:first-child {
            color: var(--text-font-color6);
            margin-right: 8px;
          }
          &:last-child {
            color: var(--text-font-color11);
            flex: 1;
          }
        }
      }
    }
  }

  .main {
    padding: 20px;
  }
}