.RFM {
  .rfm-search-bgd {
    width: 100%;
    height: 112px;
    background: var(--background2);
    border-radius: var(--border-radius4);
    padding: 16px;
    box-sizing: border-box;
    margin: 24px 0;
  }

  .addBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    margin-left: 8px;
  }
}

.RFM :global {
  .ant-form-item .ant-form-item-control-input-content{
    display: flex;
  }
}

.Required {
  color: var(--required);
  margin-right: 4px;
  line-height: var(--text-font19);
}
.crowdNum{
  color:var(--text-font-color7);
}

.tipBg{
  background-color: var(--background2);
  border-radius: var(--border-radius4);
  margin-top: 10px;
  padding: 10px;
}