.editUserMes_checkBox{
    :global{
      .ant-checkbox-group-item {
        margin-bottom: 16px !important;
      }
    }
  }
.editUserMes_chooseNum{
  position: absolute;
  bottom: 20px;
}