.tabsStyle{
  margin-top: 24px;
}
.tabsStyle :global{
  .ant-tabs-nav{
    margin-bottom: 24px;
  }
  .ant-tabs-tab{
    padding: 8px 0;
  }
}