.lz_underline-input :global {
    border: none;
    border-bottom: var(--border2);
    border-radius: var(--border-radius1);
    align-items: center;
    color: var(--text-font-color6);

    .ant-input-affix-wrapper {
    }

    .ant-input-prefix {
        margin-inline-end: 8px;
        color: var(--text-font-color4);
        width: 20px;
          height: 20px;
        .anticon {
          width: 20px;
          height: 20px;
          padding: 2px 3px;
          // font-size: var(--text-font10);
          svg {
            font-size: var(--text-font14);
            color: var(--text-font-color4);
          }
      }
    }
    .ant-input {
      font-weight: var(--text-font-weight1);
      font-size: var(--text-font7) !important;
    }

}

.lz_underline-form :global {
  .ant-form-item{
    margin-bottom: 0;
  }
}

.lz_underline-input:focus-within {
    box-shadow: none;
}

.lz_underline-input {}