.AccountManagement_container {
    display: flex;
    flex-direction: row;
    height: 100%;
}

.userName{
  cursor: pointer;
}

.userName:hover{
  color: var(--text-font-color7);
}

.AccountManagement_left-side :global {
    min-width: 200px;
    width: 200px;
    height: 100%;
    background: var(--background1);
    box-shadow: var(--box-shadow2);
    display: flex;
    flex-direction: column;
    align-items: center;

    .ant-tree-treenode {
        width: 100%;
    }

    .ant-tree-node-content-wrapper {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .ant-tree-title {
        // width: 100% !important;
        display: flex;
        flex-direction: row;
        flex: 1;
        // span {
        //     white-space: nowrap;
        //     overflow: hidden;
        //     text-overflow: ellipsis;
        //     flex: 1;
        // }

        .lz_underline-input {
            height: 24px;
            width: 120px;
        }
    }

    .ant-tree {
        .ant-tree-indent-unit {
            width: 12px;
        }
    }

    .ant-tree-show-line {
        .ant-tree-indent-unit:before {
            inset-inline-end: 0px;
        }
    }

    .ant-tree {
        height: calc(100vh - 64px - 56px);
        overflow-y: scroll;
    }

    .ant-tree::-webkit-scrollbar {
        width: 0;
    }

    .ant-tree::-webkit-scrollbar {
        height: 0;
    }


}

.AccountManagement_right-side {
    display: flex;
    flex-direction: column;
    padding: 0 24px 0px;
    // height: 100%;
    overflow-y: scroll;
}

.accountStatus :global{
  .ant-select .ant-select-arrow {
    font-size: 9px !important;
    right: 10px !important;
}

.ant-select-selection-item {
    padding-inline-end: 0 !important;
}

.ant-select-single .ant-select-selector {
    font-size: var(--text-font2) !important;
}

.ant-table-wrapper .ant-table-cell {
    overflow-wrap: normal !important;
    word-break: break-all !important;
}
}

.AccountManagement_header {
    line-height: var(--text-font15);
    display: flex;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: var(--z-index6);
    padding:  19.5px 24px;
    background-color: var(--background1);
    border-bottom: var(--border8);
}

.AccountManagement_header-action {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.accountStatusType1 :global {
    .ant-select-selector {
        background: var(--label-background-color5) !important;
        border: var(--label-border5) !important;
        color: var(--label5);
    }

    .ant-select-arrow {
        color: var(--label5);
    }
}

.accountStatusType0 :global {
    .ant-select-selector {
        background: var(--background4) !important;
        border: var(--border19) !important;
        color: var(--text-font-color7) !important;
    }

    .ant-select-arrow {
        color: var(--text-font-color7);
    }
}

.accountStatusType2 :global {
    .ant-select-selector {
        background: var(--background5) !important;
        border: var(--border6) !important;
        color: var(--text-font-color4);
    }

    .ant-select-arrow {
        color: var(--text-font-color4);
    }
}

.success-content {
    display: flex;
    flex-direction: column;

    .success-line {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 5px 0;

        .success-title {
            width: 90px;
            text-align: right;
            margin-right: 30px;
        }
    }
}
.line-three-point{
    width: 32px;
    height: 32px;
    border-radius: var(--border-radius5);
    display: flex;
    justify-content: center;
}