
.ruleGroup{
  padding: 16px;
  display: flex;
  margin-bottom: 24px;
  align-items: center;
  width: 100%;
  // height: 120px;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid var(--text-font-color8);
  cursor: pointer;
  /* 阴影 */
  // box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.1), -5px 0 5px 2px rgba(0, 0, 0, 0.1) ,-10px 0 5px -5px rgba(0, 0, 0, 0.1) , 0 8px 5px -5px rgba(0, 0, 0, 0.2);
}
.createChooseIcon{
  font-size: 24px;
  color: var(--text-font-color7);
  margin-right: 10px;
}
.createChooseLabel{
  display: flex;
  font-size: 16px;
  // font-weight: bold;
  margin-bottom: 10px;
  align-items: center;
}
.createChooseDetailText{
  color: var(--text-font-color34);
  line-height: 1.5;
}
.createChooseBox{
  display: flex;
}