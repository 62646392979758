.businessModel{
  padding: 0 24px 24px;
  box-sizing: border-box;
}
.headBox{
  // height: 32px;
  line-height: var(--text-font15);
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: var(--z-index6);
  padding: 19.5px 24px;
  background-color: var(--background1);
  border-bottom: var(--border8);
}
.searchBox{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.tableBox{
  // margin-top: 24px;
}
.tableName {
  cursor: pointer;
}
.tableName:hover {
 color: var(--text-font-color7);
}