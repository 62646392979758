.container {
    width: 100vw;
    height: 100vh;
    background-image: url(../../../assets/images/validation.png);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 32px 120px;

    .box {
        min-height: 515px;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: var(--background1);
        box-shadow: var(--box-shadow4);
        border-radius: var(--border-radius9);
        position: relative;

        .lz_resetbind-logo {
            position: absolute;
            top: 24px;
            left: 32px;
            width: 76px;
            height: 24px;
            background-size: 100% auto;
            background-repeat: no-repeat;
            // background-image: url(../../../assets/images/loginLogo.png);
        }

        .content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-top: 80px;

            .title {
                font-size: var(--text-font7);
                color: var(--text-font-color6);
                font-weight: var(--text-font-weight2);
                margin-bottom: 25px;
            }
        }
    }
}

.lz_login-reset-text {
    font-size: var(--text-font5);
    color: var(--text-font-color6);
    font-weight: bold;
    margin-top: 30px;
}


.lz_resetbind-operation {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between !important;
}

.lz_login-get-code-text {
    padding: 0;
    height: auto;
}