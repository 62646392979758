.historyBtn,
.helpCenterBtn {
  height: 36px;
  // background: linear-gradient(134deg, #72B2F6 0%, #1677FF 100%);
  // border-radius: 18px 18px 18px 18px;
  font-size: 14px;
  color: #1677FF;
  line-height: 36px;
  padding: 0 10px;
  cursor: pointer;
}

.headerSection {
  z-index: 100;
  background: #FAFBFF;
  height: 73px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: inset 0px -1px 0px 0px #DCDFE6;
  position: absolute;
  // width: 100%;
  width: calc(100% - 256px);
  position: absolute;
  right: 0;

  .breadName {
    font-size: 20px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
  }

}

.mainSection {
  display: flex;
  justify-content: space-between;


  position: absolute;
  left: 276px;
  width: calc(100% - 276px);

  .chatSectionWrap {
    padding-top: 73px;
    height: calc(100vh - 160px);
    // position: relative; // 这里需要恢复
    position: absolute;
    right: 0;
    overflow-y: auto;

    .left24 {
      left: 24px;
    }

    .terminalAndHelp {
      width: calc(100% - 290px);
      position: fixed;
      bottom: 116px;
      display: flex;
      right: 20px;
      justify-content: space-between;
    }

    .terminalChat {
      line-height: 24px;


      .top3content {
        text-decoration: underline;
        text-decoration-color: rgba(22, 119, 255, 0.2);
        color: #1677FF;
        cursor: pointer;
        margin-bottom: 10px;
        font-size: 12px;
        background: #E6F4FF;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #91CAFF;
        height: 20px;
        line-height: 20px;
      }

      span {
        font-size: 16px;
        color: #707C97;
        line-height: 26px;
        margin-right: 24px;
        float: left;
      }

      .no {
        width: 46px;
        height: 32px;
        background: #FFFFFF;
        border-radius: 6px 6px 6px 6px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        margin-right: 16px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.88);
        line-height: 30px;
        text-align: center;
        cursor: pointer;
        float: left;
      }

      .yes {
        width: 46px;
        height: 32px;
        background: #1677FF;
        border-radius: 6px 6px 6px 6px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        font-size: 14px;
        color: #FFFFFF;
        line-height: 30px;
        text-align: center;
        cursor: pointer;
        float: left;
      }
    }

  }

  .chatSection {
    // flex: 3;
    padding: 40px 24px 17px 0;
    // margin-top: 73px;

    .aiLogo {
      width: 42px;
      height: 42px;
      margin-right: 18px;
      float: left;
    }

    .chatStyle {
      position: absolute;
      top: 18px;
      left: 22px;
    }

    .aiLogoRight {
      position: absolute;
      right: -41px;
      top: -51px;
    }

    .chatStyles {
      width: 520px;
      border-radius: 0px 10px 10px 10px;
      border: 1px solid #DBDEE5;
      float: left;

      .header {
        height: 54px;
        background: linear-gradient(270deg, #FEFFFF 0%, #E9F1FB 100%);
        border-radius: 0px 10px 0px 0px;
        border-bottom: 1px solid #DBDEE5;
        position: relative;
      }

      .main {
        padding: 16px 24px;

        .exampleQuestionsTitle {
          font-size: 16px;
          color: rgba(0, 0, 0, 0.88);
          height: 26px;
          line-height: 26px;
          margin-bottom: 16px;
          cursor: pointer;
        }

        .exampleQuestionsItems {
          background: #F5F5F5;
          height: 44px;
          border-radius: 4px 4px 4px 4px;
          padding: 8px 12px;
          font-size: 14px;
          color: #707C97;
          margin-bottom: 16px;
          display: flex;
          align-items: center;
          cursor: pointer;

          .questionLogo {
            margin-right: 16px;
          }
        }
      }
    }
  }

}

.historySection {
  overflow-y: scroll;
  height: 100vh;
  position: absolute;
  width: 256px;
  // right: 0;
  left: 0;
  z-index: 1000;
  border-right: 1px solid rgba(0, 0, 0, 0.06);
  // box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.08),0px 3px 6px -4px rgba(0, 0, 0, 0.12),0px 9px 28px 8px rgba(0, 0, 0, 0.05);


  .title {
    padding: 0 24px;
    height: 56px;
    font-size: 16px;
    font-weight: 600;
    line-height: 56px;
    position: fixed;
    z-index: 100;
    background: #fff;
    width: 255px;
  }

  .table {
    padding: 46px 24px 0 24px;

    .tableText {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.88);
      line-height: 22px;
      cursor: pointer;
      display: flex;
    }
  }
}

.inputSection {
  padding: 17px 24px 24px 24px;
  position: absolute;
  bottom: 0;
  right: 0;
  width: calc(100% - 256px);
  height: 97px;
  background: #fff;
  border-top: 2px solid rgba(112, 124, 151, 0.15);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.noData :global {
  .ant-btn-link {
    padding: 0;
  }
}

.table :global {
  thead {
    display: none;
  }

  .ant-table-wrapper table{
    border-collapse: separate; /* 使用分离边框 */
    border-spacing: 0 8px; /* 设置行的间距为8px */
  }

  .ant-table-wrapper .ant-table-tbody>tr>td {
    border-radius: 6px;
    background: #F7F9FA;
    height: 34px;
    padding: 0 12px;
  }

  .ant-spin-container{
    height: 100vh;
  }

  .ant-table-wrapper .ant-table-pagination.ant-pagination{
    position: fixed;
    bottom: 0;
    width: 216px;
    background: #fff;
    padding: 20px 0;
    margin: 0;
  }
}

.wrapQuestionElement {
  width: 100%;
}

.questionElement {
  color: #FFFFFF;
  font-size: 16px;
  float: right;
  width: 100%;
  display: flex;
  justify-content: end;
  margin: 10px 0 78px 0;

  .content {
    background: linear-gradient(135deg, #60A9F6 0%, #2A8BF2 100%);
    box-shadow: 10px 10px 50px 0px rgba(42, 139, 242, 0.1), 15px 15px 35px 0px rgba(42, 139, 242, 0.05);
    text-align: right;
    height: 50px;
    line-height: 50px;
    border-radius: 10px 10px 0px 10px;
    padding: 0 24px;

  }
}

.answerSection {
  width: 747px;
  float: left;
  margin-bottom: 70px;

  .answerBorder {
    border: 1px solid #DBDEE5;
    border-radius: 0px 10px 10px 10px;
  }

  .header {
    // height: 128px;
    height: 54px;
    background: linear-gradient(90deg, #FEFFFF 0%, #E9F1FB 100%);
    border-radius: 0px 10px 0px 0px;
    border-bottom: 1px solid #DBDEE5;
    position: relative;

    .info {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.45);
      line-height: 18px;
      position: absolute;
      top: 50px;
      padding: 0 24px;
    }
  }


  .main {
    padding: 24px;

    .searchTypes {
      .item {
        padding: 8px 12px;
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        .typeName {
          color: #707C97;
          font-size: 14px;
          font-weight: bold;
          margin: 0 10px 0 16px;
          min-width: 46px;
        }

        .content {
          font-size: 14px;
          color: #707C97;
          margin-right: 40px;
        }
      }

      .dimension {
        background: rgba(186, 224, 255, 0.2);
      }

      .target {
        background: rgba(217, 247, 190, 0.2);
      }

      .filter {
        background: rgba(255, 229, 143, 0.2);
      }
    }

    .dataExplainBtn {
      display: flex;
      justify-content: space-between;

      .agree {
        img {
          width: 16px;
          height: 16px;
        }
      }
    }

    .dataExplainContent {
      margin-top: 12px;
      background: rgba(186, 224, 255, 0.2);
      border-radius: 4px 4px 4px 4px;
      padding: 12px 24px;
      font-size: 14px;
      color: #707C97;
      line-height: 18px;
    }
  }

  .answerMain {
    background: rgba(186, 224, 255, 0.2);
    border-radius: 4px 4px 4px 4px;
    padding: 6px 12px;
    margin-bottom: 16px;

    .answerHeader {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;
    }

    .answerIconTypes {
      margin-right: 10px;
      width: 28px;
      height: 28px;
    }
  }
}

.answerTemplate {
  // display: none;
  margin-top: 138px;
  width: 100%;
}

.space {
  position: absolute;
  bottom: -223px;
  width: 100%;
  height: 100px;
}

.noResult {
  width: 696px;
  height: 67px;
  background: rgba(0, 0, 0, 0.06);
  border-radius: 4px 4px 4px 4px;
  padding: 8px 12px;
  color: rgba(112, 124, 151, 0.88);
  margin-bottom: 24px;

  .line1 {
    display: flex;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 8px;
  }

  .line2 {
    font-size: 14px;
    margin-left: 32px;
  }
}

.suggest {
  font-size: 14px;
  display: flex;
  margin-top: 16px;
  width: 1200px;

  .name {
    margin-bottom: 14px;
    display: block;
    color: rgba(0, 0, 0, 0.88);
  }

  .content {
    color: #1677FF;
    cursor: pointer;
    margin-bottom: 10px;
    font-size: 12px;
    background: #E6F4FF;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #91CAFF;
    height: 20px;
    line-height: 20px;
    padding: 0 8px;
    margin-right: 8px;
  }
}

.dataSetAnswerSection {
  background: rgba(186, 224, 255, 0.2);
  border-radius: 4px 4px 4px 4px;
  padding: 12px 24px;
  width: 60%;
  float: left;

  .question {
    font-size: 16px;
    margin-bottom: 12px;
  }

  .main {
    display: flex;
    gap: 20px;
    line-height: 22px;
  }

  .dimensions,
  .indicators {
    .title {
      font-size: 16px;
    }

    flex: 1;
    background: #fff;
    border-radius: 4px 4px 4px 4px;
    padding: 6px 12px;
    font-size: 14px;
  }
}


.BackList_title {
  width: 68px;
  height: 24px;
  background-color: #f0f0f0;
  display: flex;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  color: #848484;
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.5);
  }
}

.BackList_text {
  margin-left: 6px;
  line-height: 24px;
}

.dataExplain {
  margin-left: 60px;
  border-radius: 4px 4px 4px 4px;
  padding: 12px 24px;
  width: 60%;
  display: flex;
}

.dataShow {
  background: rgba(186, 224, 255, 0.2);
  border-radius: 4px 4px 4px 4px;
  padding: 12px;
  color: rgba(112, 124, 151, 0.88);
  line-height: 24px;
  // display: flex;
  margin: 24px;
}

.test {
  color: red;
}

.explainQuestionsName {
  position: absolute;
  left: 128px;
  top: 18px;
  font-size: 14px;
  color: #333333;
}

.blueLine {
  width: 24px;
  height: 2px;
  background: #1677FF;
  position: absolute;
  top: 25px;
  left: 100px;
}
.chatBtn{
    height: 36px;
  background: linear-gradient(134deg, #72B2F6 0%, #1677FF 100%);
  border-radius: 18px 18px 18px 18px;
  font-size: 14px;
  color: #FFFFFF;
  line-height: 36px;
  padding: 0 10px;
  cursor: pointer;
}