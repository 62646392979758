.largeTitleStyle{
  height: 28px;
  line-height: var(--text-font18);
  font-weight: var(--text-font-weight2);
  font-size: var(--text-font10);
  color: var(--text-font-color6);
  text-align: left;
  font-style: normal;
}

.middleTitleStyle{
  height: 24px;
  line-height: var(--text-font13);
  font-weight: var(--text-font-weight2);
  font-size: var(--text-font7);
  color: var(--text-font-color9);
  text-align: left;
  font-style: normal;
}