.RFMAnalysis-bgd {
  padding: 0 24px 24px;
  box-sizing: border-box;
  .rfm-search-bgd {
    width: 100%;
    height: 112px;
    background: var(--background2);
    border-radius: var(--border-radius4);
    padding: 16px;
    box-sizing: border-box;
    margin: 24px 0;
  }
}
.container{
  margin: 0 24px;
}

.headBox{
  line-height: var(--text-font15);
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: var(--z-index6);
  padding: 24px 0 0 0;
  background-color: var(--background1);
}

.searchBox{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.complete{
  width: 64px;
  height: 22px;
  background: var(--label-background-color1);
  border-radius: var(--border-radius4);
  border: var(--border7);
  text-align: center;
  line-height: var(--text-font10);
  color: var(--text-font-color19);
  font-size: var(--text-font2);
}

.fail{
  width: 64px;
  height: 22px;
  background: var(--label-background-color5);
  border-radius: var(--border-radius4);
  border: var(--label-border5);
  text-align: center;
  line-height: var(--text-font10);
  color: var(--label5);
  font-size: var(--text-font2);
}

.pending{
  width: 64px;
  height: 22px;
  background: var(--background4);;
  border-radius: var(--border-radius4);
  border: var(--border19);
  text-align: center;
  line-height: var(--text-font10);
 color: var(--text-font-color7);
  font-size: var(--text-font2);
}

.diasabledHref{
  cursor: auto;
  color: var( --text-font-color8) !important;
}

.normalHref{
  color: var( --text-font-color9) !important;
}
.line-three-point{
  width: 32px;
  height: 32px;
  border-radius: var(--border-radius5);
  display: flex;
  justify-content: center;
}