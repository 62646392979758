.VipMes{
    width: calc(100% - 20px);
    padding-top: 16px;
}
.VipMes_title{
    font-weight: var(--text-font-weight3);
    font-size: var(--text-font5);
}
.VipMes_peopleListBox{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 24px;
}
.VipMes_Item{
    margin-right: 24px;
    margin-top: 16px;
    width: 300px;
    height: 194px;
    background-image: url(../../../../../../assets/images/vipMes.png);
    border-radius: var(--border-radius5);
    position: relative;
    padding: 20px;
    display: flex;
    flex-flow: column; 
    justify-content: space-between;
}
.VipMes_Item-grey{
    margin-right: 24px;
    margin-top: 16px;
    width: 300px;
    height: 194px;
    background-image: url(../../../../../../assets/images/vipMes.png);
    border-radius: var(--border-radius5);
    position: relative;
    padding: 20px;
    filter: grayscale(100%);
    display: flex;
    flex-flow: column; 
    justify-content: space-between;
}
.VipMes_Item-title{
    font-size: var(--text-font10);
    font-weight: var(--text-font-weight3);
    background: var(--background21); /*设置渐变的方向从左到右 颜色从ff0000到ffff00*/
    -webkit-background-clip: text;/*将设置的背景颜色限制在文字中*/
    -webkit-text-fill-color: transparent;/*给文字设置成透明*/
    padding-right: 30px;
    // height: 62.84px;
    flex: 1;
}
.VipMes_Item:nth-last-child(1){
    margin-right: 0px;
}
.VipMes_Item-data{
    margin-top: 5px;
    display: flex;
    flex: 1;
    align-items: end;
}
.VipMes_Item-rightTop{
    position: absolute;
    top: 0;
    right: 0;
    width: 58px;
    height: 36px;
    background: var(--background22);
    border-bottom-left-radius: var(--border-radius10);
    color: var(--background1);
    display: flex;
    justify-content: center;
    align-items: center;
}
.VipMes_Item-num{
    font-size: var(--text-font10);
    font-weight: var(--text-font-weight4);
    display: -webkit-box;
    word-break: break-all;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1; // 如果是3行改为3，以此类推
    overflow: hidden;
    text-overflow: ellipsis;
    width: 80px;
}
.VipMes_Item-changepop{
    flex: 1 1 33.3%;
    color:var(--text-font-color7);
    cursor: pointer;
}
.VipMes_Item-changepop-grey{
    flex: 1 1 33.3%;
    color:var(--text-font-color7);
    cursor: no-drop;
}
.changeRecord :global{
    .ant-tabs-nav-list {
        margin-left: 0px !important;
    }
}
