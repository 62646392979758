.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 24px;
}

.overviewItem {
    background: #FAFAFA;
    height: 294px;
    width: calc((100% - 72px) / 4);
    padding: 12px;
}

.overviewItemValue{
    font-size: 24px;
    font-weight: bold;
    margin: 12px 0;
}

.titleEn {
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 22px;
  font-weight: bold;
}

.titleZh {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
  font-weight: bold;
}

.chat{
    height: 80px;
    margin-top: 12px;
}

.yoy{
    margin: 12px 0;
}

.yoy, .mom{
    width: 100%;
    height: 34px;
    line-height: 34px;
    padding: 0 8px;
    border-radius: 4px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    background: #FFFFFF;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
    justify-content: space-between;
}

.radioDataWrap{
    display: flex;
    align-items: center;
    .value{
        margin-right: 6px;
    }
}

/* 向下的三角箭头 */
.arrowDown {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid #52C41A; 
}
 
/* 向上的三角箭头 */
.arrowUp {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 6px solid #F5222D; 
}