.titleAndOperate {
  // height: 28px;
  display: flex;
  justify-content: space-between;
  // height: 28px;
  line-height: var(--text-font18);
  font-weight: var(--text-font-weight2);
  color: var(--text-font-color6);
  text-align: left;
  font-style: normal;
  padding: 21.5px 24px;
  background-color: var(--background1);
  border-bottom: var(--border8);
  // margin-bottom: 24px;
  .buttonBox {
    display: flex;
    div {
      margin-right: 16px;
    }
    img {
      margin-top: 11px;
      cursor: pointer;
    }
    .line {
      height: 16px;
      margin-top: 14px;
      border-left: var(--border3);
    }
  }
}
.container {
  padding: 0px 24px 24px;
}
.tableNameEn {
  cursor: pointer;
}
.tableNameEn:hover {
  color: var(--text-font-color7);
}
