.memberCard-create-pageTwo {
  width: 825px;
  margin: 0 auto;
  .section-bgd {
    margin-bottom: 24px;
    .section-content-bgd {
      width: max-content;
      // margin-left: calc(50% - 90px);
      // transform: translateX(-50%);
    }
  }
  .card-list-bgd {
    display: flex;
    gap: 16px;
    .grade-rule-card-false {
      width: 264px;
      min-height: 122px;
      border-radius: var(--border-radius7);
      border: var(--border3);
      padding: 16px;
      box-sizing: border-box;
      cursor: pointer;
      &:hover {
        border: var(--border23);
      }
      .card-title {
        font-weight: var(--text-font-weight2);
        font-size: var(--text-font5);
        color: var(--text-font-color3);
        line-height: var(--text-font11);
        span {
          margin-left: 4px;
        }
      }
      .card-text {
        font-weight: var(--text-font-weight1);
        font-size: var(--text-font2);
        color: var(--text-font-color4);
        line-height: var(--text-font10);
        margin-top: 8px;
      }
    }
    .grade-rule-card-true {
      @extend .grade-rule-card-false;
      border: var(--border4);
      box-shadow: var(--box-shadow3);
      .card-title {
        color: var(--text-font-color7);
      }
      .card-text {
        color: var(--text-font-color9);
      }
    }
    .grade-rule-card-disabled {
      @extend .grade-rule-card-false;
      cursor: not-allowed;
      &:hover {
        border: var(--border3);
      }
    }
    .grade-rule-card-readOnly {
      cursor: not-allowed;
    }
  }
  .grouping-tips {
    color: var(--text-font-color23);
    margin-left: 48px;
  }
  .input-short {
    width: 88px;
    margin: 0 8px;
  }
  .input-long {
    width: 359px;
  }
  .upload > span > div {
    width: 44px !important;
    height: 44px !important;
    margin-bottom: 0 !important;
  }
  .ProtectionLogic-text {
    font-weight: var(--text-font-weight1);
    font-size: var(--text-font2);
    color: var(--text-font-color9);
    line-height: var(--text-font10);
    margin-bottom: 24px;
  }
  .tips {
    max-width: 500px;
    font-weight: var(--text-font-weight1);
    font-size: var(--text-font5);
   color: var(--text-font-color4);
    line-height: var(--text-font11);
  }
}
