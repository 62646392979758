.lzHeader_username {
    font-size: var(--text-font5);
    color: var(--text-font-color4);
    margin-right: 6px;
    margin-left: 8px;
}

.userInfo_content {
    // height: 410px;
    // margin-left: 5px;
    overflow-y: auto;
    padding-right: 10px;
    margin-right: -10px;
    font-weight: var(--text-font-weight2);

    p {
        width: 268px;
        position: relative;
        display: flex;
        margin: 0 0 15px;

        .lz_info_content {
            width: 195px;
            display: inline-block;
            text-align: right;

            .userInfo_content_btn {
                margin-left: 10px;
            }
        }

        .lz_info_lableName {
            width: 90px;
            /* text-align: right; */
            display: inline-block;
            position: relative;
            opacity: .45;
        }

        .userInfo_title {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}

.userInfo_div {
    display: flex;
    justify-content: flex-end
}

.userInfo_tip {
    min-width: 60px;
    text-align: left;
    margin-left: 8px;
}

.userInfo_text {
    opacity: .45;
}

.userInfo_btn {
    opacity: .45;
}

.retract {
    // margin-right: 6px;
}

.clickBtn {
    position: absolute;
    right: 0;
}

.userInfo_logout {
    display: flex;
    cursor: pointer;
}

:global {
    .ant-popover-inner {
        padding: 16px !important;
    }

    .ant-popover-content {
        margin-top: -13px;
    }
}