.StockMemberManagement-bgd {
  padding: 0 24px 24px;
  min-width: 900px;
  box-sizing: border-box;
  position: relative;

  .title {
    padding: 24px 0 24px;
    font-size: var(--text-font10);
    font-weight: var(--text-font-weight2);
    width: 100%;
    background-color: var(--background1);
    z-index: var(--z-index2);
  }

  .StockMemberManagement-content {
    height: calc(100vh - 155px);
    overflow-y: scroll;
    overflow-x: clip;

    .dashboard-box {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: -15px;
      margin-top: 16px;
      position: relative;

      .progress-text {
        color: var(--text-font-color4);
        font-size: var(--text-font2);
        margin-top: -70px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        width: 200px;
        margin-left: 20px;

        span:first-child {
        }

        span:last-child {
          width: 60px;
          text-align: center;
        }

        span:nth-child(2) {
          // margin-right: 36px;
          flex: 1;
          text-align: center;
          margin-left: 30px;
        }
      }

      .conversion {
        width: 90px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 18px;
        margin-right: 40px;

        .conversion-percent {
          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: var(--text-font2);
        }
        .arrow-img {
          width: 41px;
          height: 22px;
          margin-top: 8px;
        }
      }
      .liquid-text {
        position: absolute;
        left: 387px;
        top: 86px;
        z-index: var(--z-index5);
        font-size: var(--text-font2);
        color: var(--text-font-color4);
        width: 159px;
        text-align: center;
        div {
          font-size: var(--text-font13);
          color: var(--text-font-color3);
        }
      }
      .trans-box {
        margin-left: 10px;
        width: 260px;
        height: 108px;
        background: var(--background2);
        border-radius: var(--border-radius7);
        padding: 12px 16px;
        font-size: var(--text-font2);
        .trans-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;
        }
      }
    }
    .text-btn {
      background: transparent;
      border: none;
      box-shadow: none;
      color: var(--text-font-color3);
    }
    .text-btn:hover {
      color: var(--text-font-color7);
      background: transparent;
    }
    .buttonBox {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      justify-content: end;
      div {
        // margin-right: 16px;
      }
      img {
        margin-top: 11px;
        cursor: pointer;
      }
      .line {
        height: 16px;
        margin-top: 14px;
        border-left: var(--border3);
      }
    }
  }
}

.StockMemberManagement-content::-webkit-scrollbar {
  width: 0;
  height: 0;
}
