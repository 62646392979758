.largeTitleStyle {
  height: 28px;
  line-height: 28px;
  font-weight: 500;
  font-size: 20px;
  color: var(--text-font-color6);
  text-align: left;
  font-style: normal;
}

.middleTitleStyle {
  height: 24px;
  line-height: 24px;
  font-weight: 500;
  font-size: 16px;
  color: var(--text-font-color9);
  text-align: left;
  font-style: normal;
}
