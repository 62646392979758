.lz_menu {
    display: flex;
    flex-direction: row;
}

.lz_main-menu {
    width: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    position: relative;
    overflow-y: scroll;
    scrollbar-width: none; /* firefox隐藏滚动条 */
    -ms-overflow-style: none; /* IE 10+ */
}

.lz_main-menu::-webkit-scrollbar {
    width: 0;
}

.lz_main-menu::-webkit-scrollbar {
    height: 0;
}

.lz_menu-logo {
    width: 48px;
    // height: 28px;
    margin: 20px 0;
}