.searchBox{
  display: flex;
  justify-content: end;
  margin-bottom: 24px;
  img{
    vertical-align: sub;
    margin-right: 16px;
    cursor: pointer;
  }
  .line{
    display: inline-block;
    width: 16px;
    height: 16px;
    border-left: var(--border3);
    vertical-align: text-bottom;
    margin-bottom: 1px;
  }
}