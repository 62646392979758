.BackList_title{
    width: 68px;
    height: 24px;
    background-color: var(--background6);
    display: flex;
    border-radius: var(--border-radius5);
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    color: var(--text-font-color12);
    cursor: pointer;
    &:hover {
        background: var(--background11);
        color: var(--text-font-color14);
      }
}
.BackList_text{
    margin-left: 6px;
    line-height: var(--text-font13);
}