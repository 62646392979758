.titleText {
  display: flex;
}
.dataTip {
  margin-left: 10px;
  color: var(--text-font-color4);
}

.searchBox{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.createRolePromit{
  margin: 0;
  padding: 0;
  height: 150px;
  line-height: var(--text-font25);
  text-align: center;
  span{
    display: inline-block;
    margin-left: 20px;
    cursor: pointer;
    color: var(--text-font-color7);
  }
}
// .accountStatusType0 :global {
//   .ant-select-selector {
//       background: rgba(0, 0, 0, .04) !important;
//       border: var(--border6) !important;
//       color: rgba(0, 0, 0, .45);
//   }

//   .ant-select-arrow {
//       color: rgba(0, 0, 0, .45);
//   }
// }

// .accountStatusType1 :global {
//   .ant-select-selector {
//       background: var(--label-background-color1) !important;
//       // border: var(--border19) !important;
//       border: var(--border20) !important;
//       color: var(--text-font-color19) !important;
//   }

//   .ant-select-arrow {
//       color: var(--text-font-color19);
//   }
// }
// .accountStatus :global{
  // .ant-select .ant-select-arrow {
  //   font-size: 9px !important;
  //   right: 10px !important;
  // }
  // .ant-select-selection-item {
  //   padding-inline-end: 0 !important;
  // }
  
  // .ant-select-single .ant-select-selector {
  //   font-size: var(--text-font2) !important;
  // }
  
  // .ant-table-wrapper .ant-table-cell {
  //   overflow-wrap: normal !important;
  //   word-break: break-all !important;
  // }
// }
.logHeader {
  width: 100%;
  margin: 0 auto 10px auto;
  border-radius: var(--border-radius5);
  // height: 140px;
  background-color: rgba(0, 0, 0, .05);
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 10px;
  box-sizing: border-box;
}
.tableName {
  cursor: pointer;
}
.tableName:hover {
 color: var(--text-font-color7);
}
.line-three-point{
  width: 32px;
  height: 32px;
  border-radius: var(--border-radius5);
  display: flex;
  justify-content: center;
}

.checkUpload{
  color: var(--text-font-color7);
  cursor: pointer;
}