.productSales{
    padding: 0 24px;
}

.wrap{
    display: flex;
    gap: 24px;
    width: 100%;
    margin-top: 16px;
    .item{
        width: calc(50% - 12px);
    }
}