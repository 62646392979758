.DataSourceAdd {
  p {
    margin: 0;
  }
  .titleBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 24px;
    border-bottom: var(--border3);
    border-top: var(--border1);
    .title {
      font-size: 20px;
      font-weight: bold;
    }
  }
  .indicatorBox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #FFFBE6;
    padding: 12px;
    margin: 16px 0 24px;
    p.desc {
      font-size: 14px;
      margin-left: 8px;
    }
  }
}