.dark {
  @import '/@/style/skin/dark/slide-bar.less';
}

.default {
  @import '/@/style/skin/default.less';
}

.click-element {
  cursor: pointer;
}

.Page_header-title {
  font-size: var(--text-font10);
  font-weight: var(--text-font-weight2);
  color: var(--text-font-color6);
}

#__loading {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: var(--background11);
}

#__loading-img {
  width: 80px;
  height: 80px;
}
.management {
  box-sizing: border-box;
}

.headBox {
  line-height: var(--text-font15);
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: var(--z-index6);
  /* padding: 20px 24px; */
  padding: 20px 24px;
  background-color: var(--background1);
  border-bottom: var(--border8);
}
.containerBox {
  padding: 0 24px 0px 24px;
}
.ant-table-body {
  /* overflow-y: scroll;
  scrollbar-width: none; */
  /*  隐藏滚动条 */
}
