.headBox{
  line-height: 32px;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 111;
  padding: 19.5px 24px;
  background-color: #fff;
  border-bottom: 1px solid #f0f0f0;
}

.searchBox{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tableBox{
    padding: 24px;
}
