.fullScreen {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  margin-bottom: 4px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 1001;
}

.close {
  position: absolute;
  top: -32px;
  right: 0px;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  color: #888;
}

.close:hover {
  color: black;
}