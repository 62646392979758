.wholeChartMask{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    // background: rgba(0,0,0,0.5);
    z-index: 99;
}
.wholeChart {
    width: 1000px;
    height: 418px;
    border-radius: 10px;
    background: #fff;
    z-index: 100;
    margin: 0 auto;
    top: calc(50% - 128px);
    position: relative;
    // 边框阴影
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.18);
    overflow: hidden;
}

.closeBtn {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 101;
    cursor: pointer;
}

.title {
    width: 100%;
    height: 60px;
    line-height: 60px;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    color: #333333;
}

.closeBtn{
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 101;
    cursor: pointer;
}