.buttonBox{
  display: flex;
  justify-content: end;
  height: 32px;
  margin-bottom: 16px;
}

.drawerStyle :global{
  .ant-drawer-body{
    padding: 16px 24px 24px 24px;
  }
}

.filterBox{
  display: flex;
  justify-content: space-between;
  background-color: var(--background2);
  padding: 16px;
  border-radius: var(--border-radius4);
  margin-bottom: 10px;
}