.exportExplainTable{
    display: flex;
    margin-top: 24px;
}
.exportExplainItem{
    width: 95%;
}
.exportExplainMes{
   line-height: 2;
}
.exportExplainText{
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
    margin-top: 30px;
}