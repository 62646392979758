.container {
  margin: 0 24px;
}

.headBox {
  line-height: 32px;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 111;
  padding: 24px 0 0 0;
  background-color: #fff;
}

.searchBox {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.complete {
  width: 64px;
  height: 22px;
  background: #F6FFED;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #B7EB8F;
  text-align: center;
  line-height: 20px;
  color: #52C41A;
  font-size: 12px;
}

.fail {
  width: 64px;
  height: 22px;
  background: #FFF1F0;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #FFA39E;
  text-align: center;
  line-height: 20px;
  color: #F5222D;
  font-size: 12px;
}

.pending {
  width: 64px;
  height: 22px;
  background: #E6F4FF;;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #91CAFF;
  text-align: center;
  line-height: 20px;
  color: #1677FF;
  font-size: 12px;
}

.disabledHref {
  cursor: auto;
  color: var(--text-font-color8) !important;
}

.normalHref {
  color: var(--text-font-color9) !important;
}

.line-three-point {
  width: 32px;
  height: 32px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
}


.fieldExpressionBox {
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-right: 0;

  :global .ant-col {
    border-right: 1px solid rgba(0, 0, 0, 0.06);
  }

  .header {
    box-sizing: border-box;
    padding-left: 16px;
    height: 54px;
    line-height: 54px;
    background: rgba(0, 0, 0, 0.02);
    font-weight: 600;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.88);
  }

  .treeBox {
    padding: 16px;
  }

  .editSign {
    box-sizing: content-box;
    padding-top: 16px;
    padding-left: 16px;
    height: 32px;
    font-weight: 400;
    font-size: 14px;
    color: #1677FF;
    line-height: 32px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  }
}

.fieldExpressionForm {
  label::before {
    display: inline-block;
    margin-inline-end: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
  }

  .functionTipsBox {
    margin-bottom: 16px;
    padding: 11px 17px;
    background: #EBF3FD;
    box-shadow: 0 0 12px 0 rgba(169, 182, 199, 0.15);
    border-radius: 10px 10px 10px 10px;
    border: 1px solid #BADEFF;
    display: flex;
    gap: 8px;
    align-items: flex-start;

    .functionTipsText {
      font-size: 12px;
      color: #333333;
      font-weight: 400;
    }
  }
}
