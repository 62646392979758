.homePageContent {
  padding: 6px 24px 52px 32px;
  .HeaderText {
    font-weight: var(--text-font-weight4);
    font-size: var(--text-font13);
    color: var(--text-font-color6);
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .deadline {
      line-height: var(--text-font11);
      font-weight: var(--text-font-weight1);
      font-size: var(--text-font5);
      color: var(--text-font-color6);
    }
  }
  .CustomerData {
    margin-top: 24px;
    .smallCardList {
      display: flex;
      justify-content: space-between;
      .smallCardItem {
        background-size: 100%;
        flex: 1;
        box-sizing: border-box;
        padding: 24px 16px;
        position: relative;
        background: var(--background23), var(--background1);
        box-shadow:var(--box-shadow6);
        border-radius: var(--border-radius7);
        overflow: hidden;
        .cardTitle {
          font-weight: var(--text-font-weight1);
          font-size: var(--text-font5);
          color: var(--text-font-color4);
          margin-bottom: 10px;
        }
        .customerData {
          font-weight: var(--text-font-weight4);
          font-size: var(--text-font13);
          color: var(--text-font-color6);
        }
        .customerImg {
          position: absolute;
          width: 175px;
          display: flex;
          right: -40px;
          bottom: -10px;

        }
      }
    }
    
  }
  .chartList {
    margin-top: 24px;
    .pieChartName {
      font-weight: var(--text-font-weight3);
      font-size: var(--text-font7);
    }
  }
  .recentVisit {
    margin-top: 24px;
    .tabChildren {
      display: flex;
      flex-wrap: wrap;
      .kanbanItem {
        background: var(--background5);
        border-radius: var(--border-radius7);
        padding: 20px 0px 20px 20px;
        font-weight: var(--text-font-weight2);
        font-size: var(--text-font5);
        color: var(--text-font-color9);
        position: relative;
        margin-right: 24px;
        margin-top: 10px;
        // margin-bottom: 20px;
        box-sizing: border-box;
        display: flex;
        cursor: pointer;
        .itemIcon {
          font-size: var(--text-font10);
         color: var(--text-font-color7);
          margin-right: 12px;
          font-weight: var(--text-font-weight2);
        }
      }
      .groupingItem {
        background: var(--background24);
        border-radius: var(--border-radius4);
        padding: 16px;
        margin-right: 24px;
        margin-top: 10px;
        // margin-bottom: 20px;
        cursor: pointer;
        .groupName {
          font-weight: var(--text-font-weight1);
          font-size: var(--text-font5);
          color: var(--text-font-color4);
        }
        .peopleNum {
          font-weight: var(--text-font-weight2);
          font-size: var(--text-font12);
          color: var(--text-font-color9);
          margin: 8px 0px;
        }
        .labelName {
          font-weight: var(--text-font-weight2);
          font-size: var(--text-font9);
          color: var(--text-font-color9);
          margin: 0px 0px 20px 0;
          // text-wrap: nowrap;
        }
        .updateTime {
          font-weight: var(--text-font-weight1);
          font-size: var(--text-font2);
          color: var(--text-font-color6);
        }
      }
    }
    .emptyStatus {
      margin: 20px auto;
      .text {
        text-align: center;
        margin-top: 10px;
        font-weight: var(--text-font-weight1);
        font-size: var(--text-font5);
        color: var(--text-font-color4);
      }
      .img {
        width: 101px;
        display: block;
        margin: 0 auto;
      }
    }
  }
}
.chartList :global {
  .ant-card-body {
    // padding: 0 24px 0 24px
  }

}
.recentVisit :global {
  .ant-card-body {
    // padding: 24px 24px 4px 24px
  }
}
.questionIcon {
  cursor: pointer;
  margin-left: 10px;
  font-weight: normal;
  color: var(--text-font-color4);
}