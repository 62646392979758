.lzHeader_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    background: var(--background1);
    /* box-shadow: var(--box-shadow1); */
    padding: 0 25px;
    height: 56px;
}

.lzHeader_icon {
    font-size: var(--text-font1);
    color: var(--text-font-color4);
    margin: 0 24px 0 0;
}

.lzHeader_avatar {
    width: 28px;
    height: 28px;
    border-radius: 50%;
}

.lzHeader_userInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
}

