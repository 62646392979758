.monthBox{
  width: 286px;
  padding: 0 8px;
  box-sizing: border-box;
  height: 264px;
}

.monthItem{
  display: inline-block;
  text-align: center;
  width: 90px;
  height: 66px;
  padding-top: 21px;
}

.monthItemInner{
  width: 100%;
  height: 24px;
  line-height: var(--text-font13);
}

.item{
  display: inline-block;
  width: 60px;
  height: 24px;
  line-height: var(--text-font13);
  border-radius: var(--border-radius4);
  text-align: center;
  cursor: pointer;
}
.item:hover{
  background-color: var(--text-font-color7) !important;
  color: var(--background1) !important;
}

.rangeStyle{
  background-color: var(--background4) !important;
}

.itemSelect{
  background-color: var(--text-font-color7) !important;
  color: var(--background1) !important;
}

.itemUnSelect{
  background-color: var(--background1);
  color: var(--text-font-color9);
}