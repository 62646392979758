.SystemLog{
  padding: 0 24px 24px;
  max-height: 100%;
  overflow-y: auto;
}

.headBox{
  line-height: var(--text-font15);
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: var(--z-index6);
  padding: 19.5px 24px;
  background-color: var(--background1);
  border-bottom: var(--border8);
}

.searchBox{
  display: flex;
  flex-direction: row;
  align-items: center;
}


.accountStatusType0 :global{
  .ant-select-selector {
    background: var(--background4) !important;
    border: var(--border19) !important;
    color: var(--text-font-color7);
}

  .ant-select-arrow {
      color: var(--text-font-color7);
  }
}

.line_two{
  width: 200px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}