.container{
    margin: 0 24px;
}

.wrap{
    display: flex;
    gap: 24px;
    width: 100%;
    margin-top: 16px;
    .item{
        width: calc(50% - 12px);
    }
}

.wrapLinkage{
    display: flex;
    width: 100%;
    margin-top: 16px;
    .item{
        width: 50%;
    }
}

.header{
    margin-top: 18px;
    display: flex;
    justify-content: space-between;
    margin: 36px 0 16px 0;
    align-items: center;
    div{
        font-size: 16px;
        color: rgba(0, 0, 0, 0.88);
        font-weight: bold;
    }
}

.content{
    padding: 24px 12px;
}