.dataAccessDetailsList{
  // padding: 0 24px 24px;
}

.headBox{
  // margin-bottom: 28px;
  // display: flex;
  // justify-content: space-between;
  // line-height: var(--text-font15);
  // display: flex;
  // justify-content: space-between;
  // position: sticky;
  // top: 0;
  // z-index: var(--z-index6);
  // padding: 24px 0 24px;
  // background-color: var(--background1);
}

.titleBox{
  // height: 40px;
  display: flex;
  align-items: center;

}

.returnBtn{
  width: 68px;
  height: 24px;
  background-color: var(--background6);
  display: flex;
  border-radius: var(--border-radius5);
  justify-content: center;
  margin-right: 12px;
  // margin-top: 8px;
  color: var(--text-font-color12);
  cursor: pointer;
  &:hover {
    background: var(--background11);
    color: var(--text-font-color14);
  }
  span {
    margin-left: 6px;
    line-height: var(--text-font13);
  }
}

.moduleTitle{
  // margin-top: 6px;
  margin-right: 12px;
}

.baseInfoBox{
  margin-top: 24px;
  margin-bottom: 20px;
  display: flex;
  div{
    width: 25%;
    font-size: var(--text-font5);
    color: var(--text-font-color6);
  }
}
.greyText{
  color: var(--text-font-color3);
  opacity: 0.45;
}

