.modalBox{
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  div{
    display: inline-block;
    p{
      text-align: center;
      margin: 0;
      padding: 0;
      font-size: var(--text-font7);
    }
  }
}