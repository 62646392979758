.RFMAnalysis-bgd {
  padding: 0 24px 24px;
  box-sizing: border-box;
  .top-info-card {
    margin: 24px 0;
    p {
      margin: 0;
      line-height: var(--line-heigth1);
    }
    .title-box {
      display: flex;
      justify-content: space-between;
      .title {
        font-size: var(--text-font10);
        color: var(--text-font-color6);
        font-weight: var(--text-font-weight2);
      }
      .time {
        font-size: var(--text-font5);
        color: var(--text-font-color6);
      }
    }
    .info-card {
      display: flex;
      flex-wrap: wrap;
      & > div {
        width: 33%;
        margin: 8px 0;
        .title {
          font-size: var(--text-font5);
          color: var(--text-font-color6);
        }
      }
    }
  }
}
.returnBtn{
  height: 24px;
  line-height: var(--text-font13);
  padding: 0 8px;
  display: inline-block;
  font-weight: var(--text-font-weight1);
  font-size: var(--text-font5);
  color: var(--text-font-color4);
  background-color: var(--background3);
  border-radius: var(--border-radius4);
  margin-right: 12px;
  vertical-align: text-bottom;
  margin-top: 1px;
  cursor: pointer;
}