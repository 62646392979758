.dimensionModel{
  padding: 0 24px 24px;
  box-sizing: border-box;
}
.headBox{
  // height: 32px;
  line-height: var(--text-font15);
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: var(--z-index6);
  padding: 24px 0 24px;
  background-color: var(--background1);
}
.searchBox{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tableName {
  cursor: pointer;
}
.tableName:hover {
 color: var(--text-font-color7);
}
.BackList_title{
  width: 68px;
  height: 24px;
  background-color: var(--background6);
  display: flex;
  border-radius: var(--border-radius5);
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  margin-top: 1px;
  color: var(--text-font-color12);
  cursor: pointer;
  &:hover {
    background: var(--background11);
    color: var(--text-font-color14);
  }
}
.BackList_text{
  margin-left: 6px;
  line-height: var(--text-font13);
}
.detailTableBox {
  margin-top: 24px;
  padding: 0 24px 0px 24px;
}
.detailTableBox :global {
  .editable-cell {
    position: relative;
  }
  
  .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
    height: 32px;

  }

  .editable-row .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }
  // .editable-row:hover .editable-cell-value-wrap {
  //   padding: 4px 11px;
  //   border: 1px solid #d9d9d9;
  //   border-radius: 2px;
  // }
}
// .uploadForm :global {
//   .ant-form-item .ant-form-item-label {
//     text-align: left;
//     width: 100px;
//   }
//   .ant-upload-wrapper {
//     flex: 1;
//   }
//   :where(.css-dev-only-do-not-override-11xg00t).ant-col-8 {
//     flex: 0 0 21%;
//   }
//   :where(.css-dev-only-do-not-override-11xg00t).ant-row {
//     // justify-content: space-evenly;
//   }
//   .ant-upload-list-item {
//     margin-top: 35px!important;
//   }
// }
.downLoad_button{
  position: absolute;
  right: 0;
  top: -35px;
}