.container :global {
  width: 100%;
  background-color: #f5f5f5;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  overflow: visible;
  margin-top: 10px;

  .search-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow: hidden;

    .search-item {
      width: calc(100% / 3);
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  .ant-select-selection-search {
    font-size: 14px;
  }

  .ant-select-selection-placeholder {
    font-size: 14px;
  }
}
