.ProductSegmentation {
  .header {
    padding: 20px 20px 10px;
    .firstRow {
      line-height: var(--text-font15);
      display: flex;
      justify-content: space-between;
      position: sticky;
      top: 0;
      z-index: var(--z-index6);
      background-color: var(--background1);
    }
    .searchBox {
      display: flex;
      align-items: center;
    }
  }
  .main {
    padding: 24px;
  }
  .line-three-point{
    width: 32px;
    height: 32px;
    border-radius: var(--border-radius5);
    display: flex;
    justify-content: center;
  }
}