.memberCard-create-pageThree {
  width: 825px;
  margin: 0 auto;
  .section-bgd {
    margin-bottom: 24px;
    .section-content-bgd {
      width: max-content;
      // margin-left: calc(50% - 90px);
      // transform: translateX(-50%);
    }
  }
  .input-short {
    width: 88px;
    margin: 0 8px;
  }
  .input-long {
    width: 359px;
  }
  .align-center {
    line-height: var(--text-font5);
    display: flex;
    align-items: center;
  }
  .tips {
    max-width: 500px;
    font-weight: var(--text-font-weight1);
    font-size: var(--text-font5);
    color: var(--text-font-color4);
    line-height: var(--text-font11);
  }
}
