.roleGroupsSetting{
  border-radius: var(--border-radius6);
  background-color: var(--background2);
  padding: 16px 16px 0;
  margin-bottom: 8px;
  box-sizing: border-box;
}

.headerBox{
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.name{
  font-weight: var(--text-font-weight2);
  font-size: var(--text-font5);    
  color: var(--text-font-color9);  
  line-height: var(--text-font11);
}

.modifyName :global{
  width: 320px;
}