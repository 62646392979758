.container{
    margin: 0 24px;
}

.ttl{
    margin: 20px 0;
}

.members{
    margin: 64px 0 20px 0;
}

.headBox{
  line-height: var(--text-font15);
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: var(--z-index6);
  padding: 19.5px 24px;
  background-color: var(--background1);
  border-bottom: var(--border8);
  flex-direction: column;
  .titleColumn{
    display: flex;
    justify-content: space-between;
  }
}

.searchBox{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.titleBox{
  display: flex;
  align-items: center;
}

