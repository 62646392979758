.cardBox{
  background: var(--background1);
  border-radius: var(--border-radius6);
  border: var(--border3);
  margin-bottom: 16px;
}
.headerBox{
  height: 56px;
  line-height: var(--text-font28);
  border-bottom: var(--border3);
  padding: 0 18px 0 16px;
  display: flex;
  justify-content: space-between;
}

.openIcon{
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: var(--text-font13);
  cursor: pointer;
  vertical-align: middle;
}

.bodyBox{
  padding: 16px;
}

.addBox{
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.modifyName :global{
  width: 320px;
}


