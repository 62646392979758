.rule-tag-tree :global {

    .ant-tree-indent-unit {
        width: 12px;
    }

    .ant-tree-show-line {
        .ant-tree-indent-unit:before {
            inset-inline-end: 0px;
        }
    }

    .ant-tree-treenode {
        width: 100%;
    }

    .ant-tree-node-content-wrapper {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .ant-tree-title {
        display: flex;
        flex-direction: row;
        flex: 1;
    }
}

.tree-container {
    height: calc(100vh - 104px - 170px);
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: none; /* firefox隐藏滚动条 */
    -ms-overflow-style: none; /* IE 10+ */

}

.tree-container::-webkit-scrollbar {
    display: none; /* Chrome Safari */
}

// .tree-container::-webkit-scrollbar {
//     height: 0;
//     display: none;
// }