.UserDeatil {
    display: flex;
    flex-direction: row;
    //   width: 100%;
    height: 100%;

    .content {
        flex: 1;
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: scroll;



        .header {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            justify-content: space-between;
            // padding: 24px 0 5px 24px; 
            padding: 23.5px 24px;
            position: sticky;
            top: 0;
            z-index: var(--z-index6);
            background-color: var(--background1);
            border-bottom: var(--border8);

            .header-left {
                display: flex;
                flex-direction: row;
                align-items: center;
            }
        }

        .header-tag {
            display: flex;
            flex-wrap: wrap;

            .header-tag-info {
                width: calc(100% / 3);
                margin-top: 10px;
            }
        }

        .content_tabs {
            width: 100%;
        }
    }

    .UserDeatil_top-mes {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .UserDeatil_Item {
        display: flex;
        width: 100%;
        margin-top: 8px;
    }

    .UserDeatil_Item:nth-child(1) {
        margin-top: 0px;
    }

    .UserDeatil_Item-title {
        width: 40%;
        color: var(--text-font-color18);
    }

    .UserDeatil_changeMes-icon {
        color: var(--text-font-color18);
    }

    .UserDeatil_changeMes-icon1 {
        margin-right: 8px;
        color: var(--text-font-color18);
    }

    .UserDeatil_Item-text {
        flex: 1;
    }

    .UserDeatil_Itm-icon {
        color: var(--text-font-color19);
        position: absolute;
        right: 0;
        top: 4px;
    }

    .UserDeatil_Itm-iconCopy {
        position: absolute;
        right: 0;
        top: 4px;

        &:hover {
            color: var(--text-font-color7);
        }
    }

    .UserDeatil_Itm-icon1 {
        color: var(--text-font-color25);
        position: absolute;
        right: 0;
        top: 4px;
    }

    .UserDeatil_peopleListBox {
        display: flex;
        flex-wrap: wrap;
    }

    .UserDeatil_Item-channel-box {
        flex: 1;
    }

    .UserDeatil_Itm-box {
        background-color: var(--background2);
        margin-top: 8px;
        width: 100%;
        min-height: 38px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 8px;
        border-radius: var(--border-radius5);
    }

    .UserDeatil_Itm-text {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        position: relative;
        white-space: nowrap;
        max-width: 141px;
    }

    .UserDeatil_Itm-textVip {
        display: flex;
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        position: relative;
        cursor: pointer;
        align-items: center;
    }

    .UserDeatil_Itm-img {
        width: 22px;
        height: 22px;
    }

    .UserDeatil_Itm-level {
        background-color: var(--label-background-color6);
        width: 100px;
        height: 20px;
        line-height: var(--text-font10);
        margin-left: -10px;
        padding: 0px 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .UserDeatil_channelIcon {
        width: 20px;
        height: 20px;
        margin: 0 5px;
        cursor: pointer;
    }

    .UserDeatil_Item-shopName {
        width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
    }
}

.tab-container {
    height: calc(100vh - 56px - 72px - 50px);
    overflow-y: scroll;
}