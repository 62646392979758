.GroupMes{
  width: calc(100% - 20px);
  padding-top: 16px;
}
.GroupMes_peopleListBox{
    display: flex;
    flex-wrap: wrap;
  }
  .GroupMes_Item{
    display: flex;
    width: 126px;
    margin: 16px 24px 16px 0px;
    height: 32px;
    align-items: center;
    justify-content: center;
    background-color: var(--background4);
    border-radius: var(--border-radius5);
    .GroupMes_Item-text{
      width: 65%;
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      white-space: nowrap;
      cursor: pointer;
    }
  }
.GroupMes_title{
    font-weight: var(--text-font-weight3);
    font-size: var(--text-font5);
}
.search-list {
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: auto;
  padding: 0px 20px;
  overflow: hidden;
  background-color: var(--background2) ;
  margin-bottom: 16px;
}
.GroupMes_serch-box{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}