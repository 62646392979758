.container {
  padding: 0px 24px;
  .titleAndOperate {
    display: flex;
    justify-content: space-between;
    line-height: var(--text-font18);
    font-weight: var(--text-font-weight2);
    font-size: var(--text-font10);
    color: var(--text-font-color6);
    text-align: left;
    font-style: normal;
    width: 100%;
    margin-top: 8px;
    font-size: var(--text-font5);
    color: var(--text-font-color9);
    .versionInfo {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-bottom: var(--border3);
      padding-bottom: 24px;
      padding-top: 9px;

      .versionItem {
        line-height: var(--text-font21);
        font-weight: normal;
        width: 400px;
      }
    }
    .paddingBox {
      padding: 16px 8px;
      width: 100%;

      .info {
        font-weight: var(--text-font-weight2);
        font-size: var(--text-font7);
        color: var(--text-font-color9);
      }
      .tipItem {
        white-space: nowrap;
        line-height: var(--text-font21);
        font-weight: normal;
      }
      .functionBox {
        display: flex;
        flex-direction: row;
        justify-content: start;
        border-bottom: var(--border3);
        padding-bottom: 24px;
        padding-top: 9px;
        .functionItem {
          margin-right: 16px;
        }
      }
      .reminderBox {
        display: flex;
        flex-direction: row;
        justify-content: start;
        border-bottom: var(--border3);
        padding-bottom: 24px;
        padding-top: 9px;
        .bottonBox {
          margin-top: 16px;
        }
      }
    }
  }
}
.headBox {
  line-height: var(--text-font15);
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: var(--z-index6);
  padding: 21.5px 24px;
  border-bottom: var(--border8);
  background-color: var(--background1);
}
.infoText {
  color: var(--text-font-color4);
}
.infoBox {
  width: 308px;
  border: var(--border6);
  border-radius: var(--border-radius4);
}
.margin-24 {
  margin-top: 24px;
}

.authorizationInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 24px;
  padding-top: 9px;
  flex-wrap: wrap;

  .authorizationItem {
    font-weight: normal;
    width: 400px;
    margin-bottom: 10px;
  }
  .authorizationItem-3 {
    width: 100%;
  }
  .authorizationItem-7 {
    width: 100%;
  }
}
