.searchBox{
  margin-bottom: 24px;
}

.listItemBox{
  border: var(--border3);
  border-radius: var(--border-radius6);
  margin-bottom: 24px;
}

.headerBox{
  height: 48px;
  line-height: var(--text-font16);
  border-bottom: var(--border3);
  padding: 0 12px;
  display: flex;
  justify-content: space-between;
}

.fileName{
  font-weight: var(--text-font-weight3);
  font-size: var(--text-font5);
  color: var(--text-font-color9);
  width: 230px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.operate1{
  display: inline-block;
  height: 22px;
  line-height: var(--text-font10);
  text-align: center;
  background-color: var(--label-background-color6);
  border: var(--label-border6);
  color: var(--label6);
  font-weight: var(--text-font-weight1);
  font-size: var(--text-font2);
  border-radius: var(--border-radius4);
  padding: 0 8px;
  margin-top: 13px;
  white-space: nowrap;
}

.operate2{
  display: inline-block;
  height: 22px;
  line-height: var(--text-font10);
  text-align: center;
  background-color: var(--label-background-color5);
  border: var(--label-border5);
  color: var(--required);
  font-weight: var(--text-font-weight1);
  font-size: var(--text-font2);
  border-radius: var(--border-radius4);
  padding: 0 8px;
  margin-top: 13px;
  cursor: default;
  white-space: nowrap;

}

.bodyBox{
  padding: 24px 12px 20px 12px;
  div{
    font-weight: var(--text-font-weight1);
    font-size: var(--text-font5);
    color: var(--text-font-color4);
    line-height: var(--text-font11);
    text-align: justify;
    margin-bottom: 8px;
  }
}

.noData{
  width: 100%;
  text-align: center;
  height: 300px;
  line-height: var(--text-font26);
  color: var(--text-font-color4);
}

.paginationSimple :global {
  .ant-pagination-total-text {
    vertical-align: top;
  }
  }