.CrossOrderJoint {
  .title {
    font-weight: var(--text-font-weight3);
    font-size: var(--text-font7);
  }
  .questionIcon {
    cursor: pointer;
    margin-left: 10px;
    font-weight: normal;
    color: var(--text-font-color4);
  }
  .subTitle {
    font-size: var(--text-font5);
    margin: 16px 0;
  }
}