.dataBoard_timeTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: var(--text-font-weight3);
  font-size: var(--text-font7);
  color: var(--text-font-color9);
  line-height: var(--text-font13);
  .section_right {
    font-weight: var(--text-font-weight1);
    font-size: var(--text-font5);
    color: var(--text-font-color9);
    line-height: var(--text-font11);
  }
  .icon {
    color: var(--text-font-color4);
    font-size: var(--text-font7);
    margin-left: 16px;
  }
}

.dataBoard_tableSearch {
  width: 100%;
  min-height: 82px;
  border-radius: var(--border-radius4);
  margin-top: 24px;
  padding: 16px;
  box-sizing: border-box;
}

.dataBoard_level {
  position: relative;
  width: 350px;
  left: 50%;
  transform: translateX(-50%);
  .content {
    width: 350px;
    height: 252px;
    position: absolute;
    top: 20px;
    left: 0;
    .bgColorBlue {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: var(--text-font-weight3);
      font-size: var(--text-font5);
      color: var(--text-font-color17);
      line-height: var(--text-font13);
    }
    .bgColorWhite {
      @extend .bgColorBlue;
      color: var(--background1);
    }
    .tooltips {
      position: absolute;
      display: flex;
      align-items: center;
      top: 0;
      font-weight: var(--text-font-weight2);
      font-size: var(--text-font5);
      color: var(--text-font-color6);
      line-height: var(--text-font11);
      .rate {
        color: var(--text-font-color4);
        margin-left: 8px;
      }
      .solid {
        width: 40px;
        height: 1px;
        border-top: var(--border22);
        margin-right: 8px;
      }
    }
  }
}

.levelChart_empty {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.dataBoard_titleWithExpansion {
  position: sticky;
  top: 0;
  z-index: var(--z-index3);
  background-color: var(--background1);
  padding: 24px 0 24px;
  .MiniStyle_TitleWithExpansion {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-size: var(--text-font10);
      font-weight: var(--text-font-weight2);
    }
    .icon {
      color: var(--text-font-color4);
      font-size: var(--text-font7);
      margin-left: 16px;
    }
  }
  .section_right {
    font-weight: var(--text-font-weight1);
    font-size: var(--text-font5);
    color: var(--text-font-color9);
    line-height: var(--text-font11);
  }
}

.dataBoard_pie-title {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translateX(-50%);
}
.dataBoard_pie-title--font {
  color: var(--text-font-color18);
  font-size: var(--text-font5);

}
