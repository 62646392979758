.dataAccessDetails {
  // padding: 16px 24px 0;
}

.headBox {
 padding: 16px 24px;
 border-bottom: var(--border8);
  // padding-bottom: 16px;
  // margin-bottom: 24px;
}

.titleBox {
  line-height: var(--text-font22);
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}

.returnBtn {
  width: 68px;
  height: 24px;
  background-color: var(--background6);;
  display: flex;
  border-radius: var(--border-radius5);
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  // margin-top: 8px;
  color: var(--text-font-color12);
  cursor: pointer;
  &:hover {
    background: var(--background11);
    color: var(--text-font-color14);
  }
  span {
    margin-left: 6px;
    line-height: var(--text-font13);
  }
}

.moduleTitle {
  // margin-top: 6px;
  margin-right: 12px;
}

.miniLabel {
  // margin-top: 10px;
}

.baseInfomation {
  display: flex;
  line-height: var(--text-font11);

  div {
    flex: 1;
  }
}

.middleTitle {
  margin-bottom: 24px;
}

.details {
  padding-bottom: 32px;
  border-bottom: var(--border3);
  // margin-bottom: 24px;
}

.items {
  display: flex;
  margin-bottom: 16px;
  font-weight: normal;
  font-size: var(--text-font5);
  color: var(--text-font-color9);
  line-height: var(--text-font11);
}

.itemsTitle{
  width: 70px;
}

.radioBox {
  margin-bottom: 16px;
}

.radioBox :global {
  .ant-radio-wrapper {
    margin-right: 16px;
  }
}

.labelStyle {
  span {
    display: inline-block;
    color: var(--required);
    margin-right: 4px;
  }

  font-size: var(--text-font5);
  line-height: var(--text-font11);
  font-weight: normal;
  color: var(--text-font-color9);
  margin-bottom: 16px;
}

.buttonBox {
  text-align: right;
  margin-bottom: 24px;
}
.greyText{
  color: var(--text-font-color3);
  opacity: 0.45;
}
.contentBox {
  padding: 24px;
  // border-bottom: var(--border3);
}
.tableBox {
  padding: 0 24px 24px 24px;
}