.DataBoardStyles-bgd {
  padding: 24px;
  box-sizing: border-box;
  .card-bgd {
    margin-top: 24px;
    .card-item-bgd {
      flex: 1 0 376px;
      height: 428px;
      background: var(--background20);
      border-radius: 16px 16px 0 0;
      padding: 16px 24px;
      box-sizing: border-box;
      .card-item-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: var(--text-font-weight2);
        font-size: var(--text-font13);
        color: var(--text-font-color9);
        line-height: var(--text-font15);
        .title {
          font-weight: var(--text-font-weight3);
          font-size: var(--text-font7);
          color: var(--text-font-color9);
          line-height: var(--text-font13);
        }
        img {
          margin-right: 4px;
          transform: translateY(4px);
        }
      }
      .small-card-bgd {
        width: 100%;
        height: 108px;
        background: var(--background1);
        box-shadow: var(--box-shadow6);
        border-radius:var(--border-radius7);
        margin-top: 12px;
        padding: 12px 16px;
        .flex-between {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .title-gray {
            font-weight: var(--text-font-weight1);
            font-size: var(--text-font5);
            color: var(--text-font-color4);
            line-height: var(--text-font11);
          }
          .num {
            font-weight: var(--text-font-weight2);
            font-size: var(--text-font13);
            color: var(--text-font-color9);
            line-height: var(--text-font15);
          }
          .title {
            font-weight: var(--text-font-weight1);
            font-size: var(--text-font2);
            color: var(--text-font-color6);
            line-height: var(--text-font10);
          }
          .rate-0 {
            font-weight: var(--text-font-weight1);
            font-size: var(--text-font2);
            color: var(--text-font-color6);
            line-height: var(--text-font10);
            margin-left: 5px;
          }
          .rate-2 {
            @extend .rate-0;
            color: var(--text-font-color19);
          }
          .rate-1 {
            @extend .rate-0;
            color: var(--label5);
          }
        }
      }
    }
  }
  .CusomerType-card {
    width: 100%;
    border: var(--border3);
    border-radius: var(--border-radius7);
    .card-title {
      width: 100%;
      // height: 48px;
      padding: 12px;
      border-bottom: var(--border3);
    }
  }
  .SalesOverview-bgd {
    margin-top: 24px;
    .card-list {
      display: flex;
      gap: 24px;
      .card-bgd {
        min-width: 176px;
        height: 90px;
        background: var(--background2);
        border-radius: var(--border-radius4);
        padding: 16px;
        box-sizing: border-box;
        font-weight: var(--text-font-weight2);
        font-size: var(--text-font13);
        color: var(--text-font-color9);
        line-height: var(--text-font15);
        .card-title {
          font-weight: var(--text-font-weight1);
          font-size: var(--text-font5);
          color: var(--text-font-color4);
          line-height: var(--text-font11);
          margin-bottom: 4px;
        }
      }
    }
  }
}
